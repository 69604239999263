import React, { useEffect, useState } from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import './WhatsApp.scss';
import { useLocation } from 'react-router-dom';
import { removeNonNumericCharacters } from '../_lib/util';

interface IWhatsApp {
  phoneNumber: string;
  position: string;
}

const WhatsApp = ({ phoneNumber, position }: IWhatsApp) => {
  const location = useLocation();
  const [showIcon, setShowIcon] = useState<boolean>(true);
  const [iconPosition, setIconPosition] = useState<string>('bottom-right');
  useEffect(() => {
    setShowIcon(location.pathname === '/stock');
  }, [location]);
  useEffect(() => {
    if (position) {
      setIconPosition(position);
    }
  }, [position]);
  if (!showIcon) return null;
  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${removeNonNumericCharacters(phoneNumber)}`}
      target="_blank"
      rel="noreferrer"
      className={`px-whatsapp-widget ${iconPosition}`}
    >
      <WhatsAppIcon />
    </a>
  );
};
export default WhatsApp;
