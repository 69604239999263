export const isViewOnlyCustomer = (enabledTags: Record<string, boolean>) => {
  return enabledTags && enabledTags['VIEW_ONLY_CUSTOMER'];
};

export const viewOnlyCustomerPathValid = (path: string) => {
  switch (path) {
    case '/':
    case '':
    case '/stock':
      return true;
  }
  if (path.includes('/orders')) return true;
  return false;
};

export const canBuyOnlineFn = (authorities: Record<string, boolean>) => {
  return authorities['CAN_BUY_ONLINE'];
};
