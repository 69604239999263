import React, { useMemo } from 'react';

import EChart, { ECOption } from '../EChart/EChart';

import { GradeMix } from '../../types/StockListTypes';

interface IGradeMixChart {
  gradeMixChart: GradeMix[];
}

function GradeMixChart({ gradeMixChart }: IGradeMixChart) {
  const chartOption: ECOption = useMemo(() => {
    // To avoid opacity of 0, we start from 0.1 to 1 => 0.9
    const opacity = 0.9 / gradeMixChart.length;
    return {
      // step up opacity for each iteration
      // add offset of 0.1 to make sure opacity is greater than 0.1.
      color: gradeMixChart.map(
        (_, index) => `rgba(85,85,85,${(opacity * (index + 1) + 0.1).toFixed(2)})`
      ),
      tooltip: { show: true },
      series: [
        {
          type: 'pie',
          data: gradeMixChart.map((mix) => ({
            value: isNaN(mix.value) ? 0 : parseInt(`${mix.value}`),
            name: mix.label,
          })),
          radius: '50%',
        },
      ],
    };
  }, [gradeMixChart]);

  return <EChart option={chartOption} />;
}

export default GradeMixChart;
