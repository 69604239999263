import * as types from '../actions/actionTypes';

export default function configReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.DEACTIVATE_TENANT:
      return { ...action.config, deactivated: true };
    case types.CONFIG_SET:
      return { ...action.config, isLoaded: true, deactivated: false };
    default:
      return state;
  }
}
