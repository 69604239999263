import { IFlags } from '../../types/IMessageType';
import * as types from '../actions/actionTypes';

export default function flagsReducer(
  state: IFlags | Object = {},
  action: { type: string; payload: IFlags }
) {
  switch (action.type) {
    case types.FLAGS_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
