import { Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface IBackButton {
  url?: string;
}

function BackButton({ url }: IBackButton) {
  const history = useHistory();
  const goBack = () => {
    if (!url) {
      return history.goBack();
    }
    return history.push(url);
  };
  return (
    <Button onClick={goBack} className="margin-0 padding-0 px-color-text">
      <ArrowBackIos />
    </Button>
  );
}

export default BackButton;
