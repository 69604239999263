import * as types from '../actions/actionTypes';

export default function orderServicesReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.ADD_TO_EXISTING_ORDER_PAYLOAD_SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
