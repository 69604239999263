import { FormControlLabel } from '@material-ui/core';
import { PrimaryCheckbox as Checkbox } from '../AtomComponents';
import React from 'react';
interface IStockListFilterItemsProps {
  level: number;
  subCat: string;
  value: string;
  itemCount: number;
  filterName: string;
  curLevel: any;
  handleChange: (e: any, level: number, subCat: string) => void;
  label: string;
}
function StockListFilterItems({
  level,
  subCat,
  value,
  itemCount,
  filterName,
  curLevel,
  label,
  handleChange,
}: IStockListFilterItemsProps) {
  return (
    <FormControlLabel
      data-level={level}
      data-name={subCat}
      data-id={subCat}
      value={value}
      disabled={itemCount === 0}
      control={
        <Checkbox
          name={`${filterName}`}
          data-id={value}
          checked={curLevel.hasOwnProperty(subCat) && curLevel[subCat].indexOf(`<|${value}|>`) > -1}
          onChange={(e: any) => {
            handleChange(e, level, subCat);
          }}
          color="primary"
        />
      }
      label={`${label} (${itemCount})`}
    />
  );
}

export default StockListFilterItems;
