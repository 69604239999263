import * as types from '../actions/actionTypes';

export default function analyticsEventReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.PURCHASE_MADE:
      return { ...state, purchase: action.payload ?? undefined };
    case types.VIEW_ITEM_LIST:
      return { ...state, viewItemList: action.payload ?? undefined };
    case types.VIEW_CART:
      return { ...state, viewCart: action.payload ?? undefined };
    case types.ADD_TO_CART:
      return { ...state, addToCart: action.payload ?? undefined };
    case types.REMOVE_FROM_CART:
      return { ...state, removeFromCart: action.payload ?? undefined };
    case types.VIEW_ITEM:
      return { ...state, viewItem: action.payload ?? undefined };
    case types.BEGIN_CHECKOUT:
      return { ...state, beginCheckout: action.payload ?? undefined };
    case types.CREATE_ACCOUNT:
      return { ...state, createAccount: action.payload ?? undefined };
    default:
      return state;
  }
}
