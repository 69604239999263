import { ALL_VIA_PHONEX_AND_HAS_EXTERNAL_SYSTEM } from '../types/enumToLabelsMap';
export type nullStr = undefined | null | string;
export const awaitingShippingQuoteOrder = (val: nullStr) => val === 'AWAITING_SHIPPING_QUOTE';
export const onHoldOrder = (val: nullStr) => val === 'ON_HOLD';
export const canceledOrder = (val: nullStr) => val === 'CANCELED';
export const shippedOrder = (val: nullStr) => val === 'SHIPPED';
export const partiallyShippedOrder = (val: nullStr) => val === 'PARTIALLY_SHIPPED';
export const invoicedOrder = (val: nullStr) => val === 'INVOICED';
export const billMyAccountOrder = (val: nullStr) => val === 'BILL_MY_ACCOUNT';
export const requestAQuoteOrder = (val: nullStr) => val === 'REQUEST_A_QUOTE';
export const awaitingPaymentOrder = (val: nullStr) => val === 'AWAITING_PAYMENT';
export const acmeExpressShippingOrder = (val: nullStr) => val?.startsWith('ACME_EXPRESS_SHIPPING');
export const acmeStandardShippingOrder = (val: nullStr) =>
  val?.startsWith('ACME_STANDARD_SHIPPING');
export const unPaidOrder = (val: nullStr) => val === 'UNPAID';
export const releasedOrder = (val: nullStr) =>
  !onHoldOrder(val) && !awaitingShippingQuoteOrder(val);
export const isTermsOrCODPaymentMethod = (val: nullStr) => val === 'COD' || val === 'TERMS_NET_30';
export const isAllViaPhonexAndHasExternalSystem = (val: nullStr) =>
  val === ALL_VIA_PHONEX_AND_HAS_EXTERNAL_SYSTEM;
export const pendingOrder = (val: nullStr) => val === 'PENDING';
export const isShippingOrder = (val: nullStr) => val === 'SHIPPING_ORDER';
export const isConsolidatedOrder = (val: nullStr) => val === 'CONSOLIDATED';
