import React from 'react';
import StockListItem from './StockListItem';
import './StockList.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as stockActions from '../../redux/actions/stockGroupActions';
import { I18nContext } from '../../I18n';
import { canBuyOnlineFn, isViewOnlyCustomer } from '../../_lib/tagUtil';
import Progress from '../Progress/Progress';

function StockList(props: any) {
  const I18n = React.useContext(I18nContext);

  const {
    pageDataState,
    stockListOffers,
    stockListSettings,
    pxnSettings,
    actions,
    authorities,
    enabledTags,
    setupCompleted,
    stockAlerts,
    openGradePopup,
    isPxns,
    ...rest
  } = props;

  const onUpdateStockAlerts = (itemNumberWarehouse: string, payload: any) => {
    actions.stockAlertsUpdate(itemNumberWarehouse, payload);
  };

  return (
    <>
      {pageDataState ? (
        pageDataState.length > 0 ? (
          <div className="grid-x">
            {pageDataState.map((group: any) => {
              return (
                <StockListItem
                  key={group.key}
                  group={group}
                  title={group.title}
                  stockListSettings={stockListSettings}
                  isPxns={isPxns}
                  {...rest}
                  offers={stockListOffers || {}}
                  stockOffersUpdate={actions.stockOffersUpdate}
                  stockAlertsUpdate={onUpdateStockAlerts}
                  canUserBuy={canBuyOnlineFn(authorities)}
                  showBuyOnlineBtn={!isViewOnlyCustomer(enabledTags)}
                  canUserAlert={authorities['CAN_SET_STOCKLIST_ALERT']}
                  openGradePopup={openGradePopup}
                  setupCompleted={setupCompleted}
                />
              );
            })}
          </div>
        ) : (
          <div className="margin-vertical-3">{I18n.noItemsFound || 'No items found!'}</div>
        )
      ) : (
        <div className="margin-top-2">
          <Progress />
        </div>
      )}
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    stockListOffers: state.stockGroupState.offers,
    stockAlerts: state.stockGroupState.alerts,
    authorities: state.userState.authorities,
    enabledTags: state.userState.userInfo?.enabledTags,
    setupCompleted: state.userState.userInfo?.setupCompleted,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(stockActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockList);
