import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Dialog from '../Dialog/Dialog';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';
import { I18nContext } from '../../I18n';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import FileWell from '../AtomComponents/FileWell';

const phraseUseSpreadsheetColsMap = {
  acceptsOffers: `Make offer requests in bulk. Use a spreadsheet (xlsx) with columns for "Item #", "Warehouse", "New Offer Quantity", and "New Offer Price"`,
  doesnotAcceptOffers: `Make purchase quantity requests in bulk. Use a spreadsheet (xlsx) with columns for "Item #", "Warehouse", "New Quantity"`,
};

export default forwardRef((props: any, ref: any) => {
  const I18n = React.useContext(I18nContext);

  const warehouseGroupAcceptsOffers = props.isPxns
    ? !!props.stockListSettings?.warehouseAcceptingOffer?.length
    : !!props?.stockListSettings?.acceptOffer;

  // import file state
  const [importFile, setImportFile] = useState<any>(null);
  // error
  const [error, setError] = useState<string>('');

  // handle file drop
  const handleDrop = (file: any) => {
    setImportFile(file[0]);
  };

  const handlerImportUpload = async (e: any) => {
    if (importFile) {
      setError('');
      await props.handleUpload(importFile);
    } else {
      // no file exists
      setError(I18n.pleaseUploadFileImport || 'Please upload the file to have a successful import');
    }
  };

  useImperativeHandle(ref, () => {
    return {
      setImportError(errorData: string) {
        setError(errorData);
      },
    };
  });

  let title = I18n.importQty || 'Import Purchase Quantity';
  if (warehouseGroupAcceptsOffers) title = I18n.importOffers || 'Import Offers';

  let phraseUseSpreadsheetColsDesc = warehouseGroupAcceptsOffers
    ? I18n.phraseUseSpreadsheetCols || phraseUseSpreadsheetColsMap.acceptsOffers
    : I18n.phraseUseSpreadsheetColsNoOffers || phraseUseSpreadsheetColsMap.doesnotAcceptOffers;

  return (
    <Dialog
      isDialogOpen={props.open}
      closeDialog={props.handleClose}
      label={props.title}
      title={title}
      content={
        <>
          <div id="alert-dialog-description px-text-description">
            <>
              <p className="px-text-description margin-bottom-1">{phraseUseSpreadsheetColsDesc}</p>
              <p className="px-text-description margin-bottom-1">
                {I18n.phraseTipUseExport || 'Tip: Use Export file as a starting point'}
              </p>
            </>
          </div>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              className="margin-bottom-1"
              onClose={() => setError('')}
            >
              {error}
            </Alert>
          )}
          <FileWell
            filesLimit={1}
            acceptedFiles={[
              '.xlsx',
              '.xls',
              'application/vnd.ms-excel',
              'application/msexcel',
              'application/x-msexcel',
              'application/x-ms-excel',
              'application/x-excel',
              'application/x-dos_ms_excel',
              'application/xls',
              'application/x-xls',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/excel',
            ]}
            handleDrop={handleDrop}
          />
          {props.isLoadingDialog && (
            <div className="grid-x align-center margin-top-1">
              <CircularProgress />
            </div>
          )}
        </>
      }
      actions={
        <>
          <TertiaryButton onClick={props.handleClose}>{I18n.cancel || 'Cancel'}</TertiaryButton>
          <PrimaryButton onClick={handlerImportUpload} autoFocus>
            {I18n.upload || 'Upload'}
          </PrimaryButton>
        </>
      }
    />
  );
});
