// import { PaymentMethod } from '../../types/OrderOptions';
import * as types from '../actions/actionTypes';

// const calculateFeeAmount = (
//   totalOrderQuantity: number,
//   orderSubtotal: number,
//   orderTotalWithoutPaymentFee: number,
//   metadata: any
// ) => {
//   const { feesApplicable, feeType, fees } = metadata;

//   if (!feesApplicable) {
//     return 0;
//   }

//   if (feeType === PaymentMethod.FIXED) {
//     return fees;
//   } else if (feeType === PaymentMethod.PER_UNIT) {
//     return totalOrderQuantity * fees;
//   } else if (feeType === PaymentMethod.PERC_OF_ORDER_SUBTOTAL) {
//     return orderSubtotal * fees;
//   } else if (feeType === PaymentMethod.PERC_OF_ORDER_TOTAL) {
//     //return orderTotalWithoutPaymentFee * fees * (1 + fees);
//     return orderTotalWithoutPaymentFee / (1 - fees) - orderTotalWithoutPaymentFee;
//   }
// };

export default function orderComputeUpdateReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.ORDER_COMPUTE_FACTORS_UPDATE: {
      const newObj = { ...state, ...action.payload };

      let combinedSubtotal = newObj['cartSubtotal'] + newObj['existingOrderSubtotal'];

      let combinedTotalQuantity =
        newObj['cartTotalQuantity'] + newObj['existingOrderTotalQuantity'];

      let orderTotalWithoutPaymentFee: number =
        combinedSubtotal +
        (newObj['additionalServiceFees'] || 0) +
        (newObj['freight'] || 0) +
        (newObj['tax'] || 0);

      // if (!action.payload.version)
      //   newObj['fees'] = calculateFeeAmount(
      //     combinedTotalQuantity,
      //     combinedSubtotal,
      //     orderTotalWithoutPaymentFee,
      //     newObj['metadata']
      //   );

      let totalPrice = orderTotalWithoutPaymentFee + newObj['fees'];

      return { ...newObj, combinedSubtotal, combinedTotalQuantity, totalPrice };
    }
    default:
      return state;
  }
}
