import React, { useEffect, useRef } from 'react';
import { sanitizeHTML } from '../../_lib/util';
import { checkNullOrUndefined } from '../../_lib/lib';

interface IShadowDom {
  htmlString: string;
  className?: string;
}
const ShadowDom = ({ htmlString, className }: IShadowDom) => {
  const shadowRoot = useRef<any>(null);

  useEffect(() => {
    if (checkNullOrUndefined(htmlString) || !shadowRoot.current) return;
    if (!shadowRoot.current.shadowRoot) shadowRoot.current.attachShadow({ mode: 'open' });
    if (shadowRoot.current.shadowRoot)
      shadowRoot.current.shadowRoot.innerHTML = `
        <link href="/quill.snow.css" rel="stylesheet">
        <style>
          .ql-container{
            font-size: 14px !important;
            border: 0 !important;
          }
          .ql-editor {
            padding: 0;
          }
          .banner-content .ql-editor{
            word-break: break-word;
          }
        </style>
        <div class="ql-container ql-snow ${className || ''}">
          <div class="ql-editor">${sanitizeHTML(htmlString)}</div>
        </div>
      `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlString]);

  return <div style={{ whiteSpace: 'initial' }} ref={shadowRoot} />;
};

export default ShadowDom;
