import * as types from '../actions/actionTypes';

export default function pusherEventReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.NOTIFICATION_EVENT:
      return { ...state, refreshLot: action.payload ?? undefined };
    case types.OUTBID_EVENT:
      return { ...state, outBidLotData: action.payload ?? undefined };
    default:
      return state;
  }
}
