import * as types from '../actions/actionTypes';

export const processReduxStockListSettings = (stocklistSettings: any) => {
  return Object.keys(stocklistSettings).reduce((acc: any, key: any) => {
    acc[key] = stocklistSettings[key];
    acc[key].filterOrder = {
      ...(acc[key].filterOrder || {}),
      grade:
        acc[key].grade?.reduce(
          (acc: Record<string, number>, grade: string, index: number) => ({
            ...acc,
            [grade]: index,
          }),
          {}
        ) || {},
    };
    return acc;
  }, {});
};

export default function stockGroupReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.STOCK_SET:
      const newStock = {
        ...state,
        results: Object.assign({}, action.stock),
        isLoaded: true,
        isComplete: action.isComplete,
      };
      return newStock;
    case types.STOCK_LOT_SET:
      return {
        ...state,
        stockLots: action.payload,
      };
    case types.STOCKLIST_SETTINGS_SET:
      return {
        ...state,
        ...action.settings,
      };
    case types.PXN_WAREHOUSES_SET:
      return {
        ...state,
        ...action.pxnParticipantWarehousesObj,
      };
    case types.STOCKLIST_OFFERS_UPDATE:
      return { ...state, offers: action.offers };
    case types.STOCKLIST_OFFERS_ITEM_UPDATE:
      return {
        ...state,
        offers: { ...state['offers'], [action.payload.itemNumberWarehouse]: action.payload.item },
      };
    case types.STOCK_ALERT_SET:
      return {
        ...state,
        alerts: action.alerts,
      };
    case types.STOCK_ALERT_UPDATE:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          ...action.alert,
        },
      };
    default:
      return state;
  }
}
