import * as types from '../actions/actionTypes';

export default function checkoutReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.CHECKOUT_PAYLOAD_SET:
      if (!action.payload.checkoutPayload?.buyerOrderPreferences)
        action.payload.checkoutPayload.buyerOrderPreferences = {}
      return { ...state, ...action.payload };
    case types.CHECKOUT_HOLD_ORDER_UNTIL_SHIPPING_QUOTE_UPDATE:
      return { ...state, isHoldOrderUntilShippingQuote: action.isHoldOrderUntilShippingQuote };
    case types.CHECKOUT_PAYMENT_INSTRUCTIONS_UPDATE:
      return { ...state, selectedPaymentInstructions: action.selectedPaymentInstructions };
    case types.CHECKOUT_UPDATE_OTHER_SHIPPING_METHOD:
      return { ...state, otherShippingMethods: action.payload };
    case types.CHECKOUT_UPDATE_REALTIME_METHOD_LABEL:
      const updatedShippingMethods = { ...state.checkoutPayload.otherShippingMethods };
      Object.keys(action.payload).forEach((val) => {
        updatedShippingMethods[val].customLabel = action.payload[val];
      });
      return {
        ...state,
        checkoutPayload: { ...state.checkoutPayload, otherShippingMethods: updatedShippingMethods },
      };
    case types.CHECKOUT_UPDATE_ORDER_NUMBER:
      return {
        ...state,
        phonexOrderNumber: action.payload,
      };
    case types.UPDATE_LINE_ITEM_PRICES: {
      const tempState = { ...state };
      tempState.checkoutPayload.lineItems = tempState.checkoutPayload.lineItems.map((item: any) => {
        if (item.itemNumber in action.payload)
          return { ...item, ...action.payload[item.itemNumber] };
        return { ...item };
      });
      return tempState;
    }
    case types.REMOVE_LINE_ITEMS_EXCEPT: {
      const updatedState = { ...state };
      updatedState.checkoutPayload.lineItems = updatedState.checkoutPayload.lineItems.filter(
        (item: any) => item.itemNumberWarehouse in action.payload
      );
      return updatedState;
    }
    case types.REMOVE_LINE_ITEMS_EXCEPT_BY_OFFERID: {
      const updatedState = { ...state };
      updatedState.checkoutPayload.lineItems = updatedState.checkoutPayload.lineItems.filter(
        (item: any) => item.offerId in action.payload
      );
      return updatedState;
    }
    default:
      return state;
  }
}
