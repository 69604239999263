import React from 'react';
import { connect } from 'react-redux';
import apiToUrlMap from '../ApiMapping';
import { useGetQueryCacheData } from '../hooks/useQueryCustomHooks/useGetQueryCacheData';
import { publicApiMapping } from '../hooks/useQueryCustomHooks/usePublicQueryStates';
import WhatsApp from './WhatsApp';
const Helpdesk = React.lazy(() => import('./Helpdesk'));
const GoogleAnalytics = React.lazy(() => import('./GoogleAnalytics'));
const Pusher = React.lazy(() => import('./Pusher'));
const PersistedScreens = React.lazy(() => import('./PersistedScreens'));
const BannerDialog = React.lazy(() => import('./BannerDialog'));
const Rum = React.lazy(() => import('./Rum'));

interface IWidgets {
  bannerState: any;
}

// we can lazily load any feature we want to
// means we can load gtd/gtm/pendo based of tenant configuration
const Widgets = ({ bannerState }: IWidgets) => {
  const userState = useGetQueryCacheData<any>(apiToUrlMap.getUserInfo);
  const configState = useGetQueryCacheData<any>(publicApiMapping.config);

  return (
    <React.Suspense fallback={<></>}>
      {configState.analytics_config && (
        <GoogleAnalytics
          analyticsConfig={configState.analytics_config}
          currencyCode={configState.currencyCode}
        />
      )}
      {configState.helpdesk_config && <Helpdesk config={configState.helpdesk_config} />}
      {userState?.userInfo?.salesRep?.salesRepWhatApp && (
        <WhatsApp
          position={configState.buyer_whatsapp_icon_position}
          phoneNumber={userState.userInfo.salesRep.salesRepWhatApp}
        />
      )}
      {configState?.pusherKey && (
        <Pusher
          tenantId={configState.tenant_id}
          pusherKey={configState.pusherKey}
          userState={userState}
        />
      )}
      <PersistedScreens />
      {bannerState[0]?.isBannerActive && (
        <BannerDialog
          isOpen={bannerState[0]?.isBannerActive}
          content={bannerState[0]?.content}
          isExternal={bannerState[0]?.isExternal === 1}
        />
      )}
      {configState?.rumConfig && (
        <Rum rumConfig={configState.rumConfig} modules={configState.modules} />
      )}
    </React.Suspense>
  );
};
const mapStateToProps = (state: any) => {
  return {
    bannerState: state.bannerState,
  };
};
export default connect(mapStateToProps)(Widgets);
