import { TextField as InputField, TextFieldProps } from '@material-ui/core';
import React from 'react';

function TextField({ children, ...props }: TextFieldProps) {
  return (
    <InputField
      color="primary"
      variant="outlined"
      {...props}
      translate={props.required ? 'no' : undefined}
    >
      {children}
    </InputField>
  );
}

export default TextField;
