import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { IMessageType } from '../../types/IMessageType';

export function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ErrorHandlerContext = React.createContext<any>(null);

export const ErrorHandler = ({ children }: { children: any }) => {
  const [isSnackBarOpen, SetIsSnackBarOpen] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<any | null>();

  const [errorType, setErrorType] = React.useState<string>();

  const openSnackBar = (errorMessage: any, errorType: IMessageType) => {
    SetIsSnackBarOpen(true);
    setErrorMessage(errorMessage);
    setErrorType(errorType);
  };

  const onClose = () => SetIsSnackBarOpen(false);

  const renderContent = () => {
    return (
      <>
        {children}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isSnackBarOpen}
          autoHideDuration={30000}
          onClose={onClose}
        >
          <Alert severity={errorType as Color} onClose={onClose}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </>
    );
  };

  const contextPayload = React.useMemo(() => ({ openSnackBar, closeSnackBar: onClose }), []);

  // We expose the context's value down to our components
  return (
    <ErrorHandlerContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorHandlerContext.Provider>
  );
};

export const useErrorHandlerContext = () => React.useContext(ErrorHandlerContext);
