import * as types from '../actions/actionTypes';

export default function inventoryReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.INVENTORY_SETTINGS_SET:
      return { ...state, settings: action.settings };
    default:
      return state;
  }
}
