import * as types from './actionTypes';

export function stockSet(stock: any) {
  return { type: types.STOCK_SET, stock };
}

export function stockLotSet(payload: any) {
  return { type: types.STOCK_LOT_SET, payload };
}

export function stockSettingsSet(settings: any) {
  return { type: types.STOCKLIST_SETTINGS_SET, settings };
}

export function stockPxnWarehousesSet(pxnParticipantWarehousesObj: any) {
  return { type: types.PXN_WAREHOUSES_SET, pxnParticipantWarehousesObj };
}

export function stockOffersSet(offers: any) {
  return { type: types.STOCKLIST_OFFERS_SET, offers };
}

export function stockOffersUpdate(offers: any) {
  return { type: types.STOCKLIST_OFFERS_UPDATE, offers };
}

export function stockOffersItemUpdate(itemNumberWarehouse: string, item: any) {
  return { type: types.STOCKLIST_OFFERS_ITEM_UPDATE, payload: { itemNumberWarehouse, item } };
}

export function stockAlertsSet(alerts: any) {
  return { type: types.STOCK_ALERT_SET, alerts };
}

export function stockAlertsUpdate(itemNumberWarehouse: string, payload: any) {
  return { type: types.STOCK_ALERT_UPDATE, alert: { [itemNumberWarehouse]: payload } };
}
