import React from 'react';
import ShadowDom from '../AtomComponents/ShadowDom';

interface IGradeDescriptionProps {
  description: string;
  className?: string;
  'data-id'?: string;
}

const GradeDescription = ({
  description,
  className,
  'data-id': dataId,
}: IGradeDescriptionProps) => {
  return (
    <div data-id={dataId} className={`cell small-12 ${className ? className : ''}`}>
      <ShadowDom htmlString={description} />
    </div>
  );
};

export default GradeDescription;
