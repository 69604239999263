import React, { useState } from 'react';
import Dialog from '../../components/Dialog/Dialog';
import { I18nContext } from '../../I18n';
import { PrimaryButton, TertiaryButton, TextField } from '../AtomComponents';

function StockAlertDialog(props: any) {
  const {
    isDialogOpen,
    closeDialog,
    title,
    stockAlert,
    setOrEditAlert,
    isEditMode,
    isBuyOfferDialogOpen,
    stockListSettings,
  } = props;
  const I18n = React.useContext(I18nContext);

  const [alertValue, setAlertValue] = useState<any>(stockAlert.alertQuantity);

  const onBlurAlertValueChange = (e: any, itemNumberWarehouse: any, incrementSize: any) => {
    let numberedVal = parseInt(e.target.value || 0);
    if (numberedVal === 0) numberedVal = 1;

    let newVal = numberedVal;

    if (incrementSize > 1)
      newVal =
        numberedVal <= incrementSize
          ? incrementSize
          : Math.floor(numberedVal / incrementSize) * incrementSize;

    setAlertValue(newVal);
  };

  const onAlertValueChange = (e: any) => {
    setAlertValue(parseInt(e.target.value || 0));
  };

  const { description, availableQuantity, itemNumberWarehouse, incrementSize } = stockAlert;

  return (
    <Dialog
      id="stockAlertDialog"
      isDialogOpen={isDialogOpen}
      closeDialog={(e: any) => closeDialog(false, null, isBuyOfferDialogOpen)}
      title={title}
      content={
        <div className="grid-x">
          <div className="cell grid-x">
            <div className="cell small-10 px-text-description">{I18n.item || 'Item'}</div>
            <div className="cell small-2 px-text-description text-center">
              {I18n.availableQty || 'Available Quantity'}
            </div>
          </div>
          <div className="cell grid-x margin-bottom-2 align-middle">
            <h5 className="cell small-10">{description}</h5>
            <div className="cell small-2 text-center">
              {stockListSettings['maxItemQuantityToBeDisplayed']
                ? availableQuantity > stockListSettings['maxItemQuantityToBeDisplayed']
                  ? `${stockListSettings['maxItemQuantityToBeDisplayed']}+`
                  : availableQuantity
                : availableQuantity}
            </div>
          </div>
          <div className="text-center">
            {I18n._reactStringReplace(
              I18n.alertWhenAddUnits || 'Alert me when you add {alert} units',
              (str: string, key: any) => (
                <label key={key} className="m-b-px-6 text-left m-l-px-10">
                  {str}
                </label>
              ),
              (str: string, key: any) => (
                <div className="grid-y alertQuantityInputField align-stretch m-l-px-10" key={key}>
                  <TextField
                    value={alertValue}
                    className="m-b-px-6"
                    onChange={(e: any) => onAlertValueChange(e)}
                    onBlur={(e) => onBlurAlertValueChange(e, itemNumberWarehouse, incrementSize)}
                  />
                  <label
                    className={`margin-top-1 m-b-px-6 m-l-px-10 text-muted ${
                      incrementSize > 1 ? 'show' : 'hide'
                    }`}
                  >
                    {I18n._format(I18n.incrementsOf || 'increments of ${1}', [
                      parseInt(incrementSize),
                    ])}
                  </label>
                </div>
              )
            )}
          </div>
        </div>
      }
      actions={
        <>
          <TertiaryButton
            size="small"
            onClick={(e) => closeDialog(false, null, isBuyOfferDialogOpen)}
          >
            {I18n.cancel || 'Cancel'}
          </TertiaryButton>
          <PrimaryButton
            onClick={() =>
              setOrEditAlert(
                itemNumberWarehouse,
                isEditMode,
                {
                  itemNumberWarehouse,
                  description,
                  quantity: alertValue,
                  availableQuantity,
                  incrementSize,
                },
                isBuyOfferDialogOpen
              )
            }
          >
            {!isEditMode ? I18n.setAlert || 'SET ALERT' : I18n.update || 'UPDATE'}
          </PrimaryButton>
        </>
      }
    />
  );
}

export default StockAlertDialog;
