import * as types from './actionTypes';

export function purchaseMade(payload: any) {
  return { type: types.PURCHASE_MADE, payload };
}

export function stocklistViewed(payload: any) {
  return { type: types.VIEW_ITEM_LIST, payload };
}
export function stocklistItemSelect(payload: any) {
  return { type: types.VIEW_ITEM, payload };
}

export function itemaddedToCart(payload: any) {
  return { type: types.ADD_TO_CART, payload };
}

export function itemRemovedFromCart(payload: any) {
  return { type: types.REMOVE_FROM_CART, payload };
}

export function cartViewed(payload: any) {
  return { type: types.VIEW_CART, payload };
}

export function checkoutOrder(payload: any) {
  return { type: types.BEGIN_CHECKOUT, payload };
}

export function createAccountAnalytics(payload: any) {
  return { type: types.CREATE_ACCOUNT, payload };
}
