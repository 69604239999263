export type IMessageType = 'error' | 'success' | 'warning';

export enum eMessageType {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export interface IFlags {
  type: 'SNACKBAR';
  message: any;
  severity: IMessageType;
  open: boolean;
}
