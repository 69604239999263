import { ButtonProps } from '@material-ui/core';
import React, { Fragment, ReactElement, useEffect, useRef, useState } from 'react';

interface IGlobalButtonWrapper {
  children: ReactElement;
  props: ButtonProps;
}

const GlobalButtonWrapper = (props: IGlobalButtonWrapper) => {
  const { disabled: btnDisabled, onClick, ...restProps } = props.props;
  const [disabled, setDisabled] = useState<boolean>(false);
  let disabledTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const btnRef = useRef();

  useEffect(() => {
    return () => {
      if (!!disabledTimerRef?.current) clearTimeout(disabledTimerRef?.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setDisabled(true);
    if (onClick) await onClick(e);
    disabledTimerRef.current = setTimeout(() => {
      if (btnRef.current) setDisabled(false);
    }, 0);
  };

  return (
    <Fragment>
      {React.cloneElement(props.children, {
        ...restProps,
        disabled: !!btnDisabled || disabled,
        onClick: onClickHandler,
        ref: btnRef,
      })}
    </Fragment>
  );
};

export default GlobalButtonWrapper;
