// inventory/settings
import * as types from '../actions/actionTypes';

export default function settingReducer(state: any = null, action: any) {
    switch (action.type) {
        case types.SETTING_SET:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}