import { NETWORK_SYSTEM_MESSAGE_SET } from '../actions/actionTypes';
import {
  INetworkSystemMessage,
  INetworkSystemMessageAction,
} from '../../types/INetworkSystemTypes';

export default function networkSystemMessageReducer(
  state: INetworkSystemMessage = {},
  action: INetworkSystemMessageAction
) {
  switch (action.type) {
    case NETWORK_SYSTEM_MESSAGE_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
