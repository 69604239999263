import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Homepage from '../views/Homepage/Homepage';
import FrameLayout from '../layouts/layout-dashboard-frame';
const AccountSetupPrivate = ({ children, ...rest }: any) => {
  const history = useHistory();
  history.listen((location: any) => {
    if (!location.search) window.scrollTo(0, 0);
  });

  return (
    <Route
      {...rest}
      render={({ location }) => {
        //show account setup page without side menu for normal prospects identified by requiresAccountSetup
        if (
          rest.redux.props.userState.requiresAccountSetup ||
          rest.redux.props.userState.gracePeriodBuyer
        )
          return <Homepage>{children}</Homepage>;
        //show account setup page with side menu for special prospects identified by setupCompleted
        if (!rest.redux.props.userState.userInfo?.setupCompleted)
          return <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>;
        //for customers that have completed customer onboarding process, redirect to home route
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default AccountSetupPrivate;
