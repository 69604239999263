import React from 'react';
import ReactDOM from 'react-dom';
import PhonexRouter from './PhonexRouter';
import ErrorBoundary from './components/ErrorHandlers/RootLevelErrorBoundary';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import configureStore from './redux/configureStore';
import { InitialState } from './redux/reducers';
import { Provider as ReduxProvider } from 'react-redux';
import I18nWrapper from './I18n';
import { ErrorHandler } from './components/ErrorHandlers/ErrorHandler';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigWrapper } from './components/Config/ConfigWrapper';
import BotActivityWrapper from './widgets/BotActivity/BotActivityWrapper';

export const store = configureStore(InitialState());

const queryClient = new QueryClient();

ReactDOM.render(
  <BotActivityWrapper>
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <ErrorHandler>
        <QueryClientProvider client={queryClient}>
          <ConfigWrapper>
            <I18nWrapper>
              <PhonexRouter />
              {process.env.REACT_APP_REACT_QUERYDEV_TOOLS === 'true' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </I18nWrapper>
          </ConfigWrapper>
        </QueryClientProvider>
      </ErrorHandler>
    </ReduxProvider>
  </ErrorBoundary>
  </BotActivityWrapper>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
