import { iSystemMessage, iSystemMessageAction } from '../../types/ISystemTypes';
import * as types from '../actions/actionTypes';

export default function systemMessageReducer(
  state: iSystemMessage = {},
  action: iSystemMessageAction
) {
  switch (action.type) {
    case types.SYSTEM_MESSAGE_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
