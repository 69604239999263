const defaultErrorObj = {
  message: 'Something went wrong',
  status: 418,
  traceId: '--',
};

export const getErrorObj = (res: any) => {
  // error object for ui errors
  const errorObj = { ...defaultErrorObj };
  if (res.reason) {
    // for api errors
    errorObj.status = res.reason.status || 0;
    errorObj.message = res.reason.message;
    errorObj.traceId = res.reason.traceId;
  }
  return errorObj;
};

export const getUIErrorObj = (error: any) => {
  const errorObj = { ...defaultErrorObj };
  // You can also log the error to an error reporting service
  if (error.stack) {
    const errorStack = error.stack.toString().split('\n');
    const traceIdLine = errorStack[1];
    const line = traceIdLine?.split(':');
    if (line) {
      const lineNumber = (line[line.length - 2] + '.' + line[line.length - 1]).replace(')', '');
      const fileNameLine = line[0].split('at')[1];
      const fileName = fileNameLine?.split('(')[0]?.trim();
      errorObj.traceId = `F:${fileName || '418'}.C:${lineNumber}`;
    }
  }

  return errorObj;
};

export const sendErrorToRum = (error: any, evtType?: string) => {
  const cwr = (window as any).cwr;
  cwr && cwr(evtType || 'recordError', error);
};
