import * as types from '../actions/actionTypes';

export default function cartReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.CHANGE_TAB:
      return { ...state, currentTab: action.payload };
    default:
      return state;
  }
}
