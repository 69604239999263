import * as types from '../actions/actionTypes';

export default function urlReducer(state: any = {}, action: any) {

  const beforeUnloadListener = (event: any, action: any) => {
    event.preventDefault();
    event.returnValue = action && action.preventWindowClose ? action.preventWindowClose.message : 'There is one task pending, do you want to leave anyways?';
    return event;
  };

  switch (action.type) {

    case types.URL_CHANGE:
      return { ...state, ...action.url };

    case types.URL_PREVENT_WINDOW_CLOSE:
      if (state.preventWindowClose) {
        window.removeEventListener('beforeunload', (event) => { beforeUnloadListener(event, action); }, { capture: true });
      }
      if (action.preventWindowClose) {
        window.addEventListener('beforeunload', (event) => { beforeUnloadListener(event, action); }, { capture: true });
      }
      return {
        ...state,
        preventWindowClose: action.preventWindowClose ? { ...action.preventWindowClose } : null
      };

    case types.URL_PREVENT_ROUTE_CHANGE:
      return {
        ...state,
        preventRouteChange: action.preventRouteChange ? { ...action.preventRouteChange } : null
      };

    case types.URL_PREVENT_LOGOUT:
      return {
        ...state,
        preventLogout: action.preventLogout ? { ...action.preventLogout } : null
      };

    default:
      return state;
  }
}
