import * as React from 'react';

declare const WebFont: any;

function hexToRGB(hex: any): any {
  if (hex.length === 4) {
    hex = hex + hex.substr(1, 3);
  }
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : '0, 1, 2';
}

function Theme(props: any) {
  React.useEffect(() => {
    const root = document.documentElement;
    const config = props.redux.props.configState;
    const classes: any = {};
    let fontTitle = null;
    let fontBody = null;
    Object.keys(config).forEach((key: string) => {
      if (key.indexOf('CSS::') > -1) {
        const cssKeyParts = key.split('::');
        if (cssKeyParts.length === 2) {
          if (key.indexOf('color') > -1) {
            root.style.setProperty(`--${cssKeyParts[1]}`, hexToRGB(config[key]));
          } else {
            root.style.setProperty(`--${cssKeyParts[1]}`, config[key]);
          }
        }
        if (cssKeyParts.length > 2) {
          if (!classes.hasOwnProperty(cssKeyParts[2])) {
            classes[cssKeyParts[2]] = {};
          }
          if (key.indexOf('color') > -1) {
            classes[cssKeyParts[2]][cssKeyParts[1]] = hexToRGB(config[key]);
          } else {
            classes[cssKeyParts[2]][cssKeyParts[1]] = config[key];
          }
        }
        if (cssKeyParts[1] === 'px_font_title') {
          fontTitle = config[key] || 'Roboto';
        }
        if (cssKeyParts[1] === 'px_font_body') {
          fontBody = config[key] || 'Roboto';
        }
      }
    });
    // Get Font
    try {
      WebFont.load({
        google: {
          families: [fontTitle ? fontTitle : 'Roboto', fontBody ? fontBody : 'Roboto'],
        },
      });
    } catch (error: any) {
      console.error('error while loading the webfont', error.message);
    }
    const css = document.createElement('style');
    css.innerHTML = `
    *:not([class*="ag-"]) {
      font-family: ${fontTitle} !important;
    }
    `;
    const variantClasses = Object.keys(classes);
    variantClasses.forEach((vc: string) => {
      css.innerHTML += `
      .${vc} {
      `;
      Object.keys(classes[vc]).forEach((property: string) => {
        css.innerHTML += `
          --${property}: ${classes[vc][property]};
        `;
      });
      css.innerHTML += `
      }
      `;
    });
    document.head.appendChild(css);
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.type = 'image/png';
    favicon.href = props.redux.props.configState.favicon;
    document.head.appendChild(favicon);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const prepareTheme = createMuiTheme({
  //   palette: {
  //     primary: {
  //       main: `rgb(var(--px_color_primary))`,
  //       contrastText: `rgb(var(--px_color_text_on_primary))`,
  //     },
  //     secondary: {
  //       main: `rgb(var(--px_color_secondary))`,
  //       contrastText: `rgb(var(--px_color_text_on_secondary))`,
  //     },
  //   },
  //   props: {
  //     MuiRadio: {
  //       color: 'primary',
  //     },
  //   },
  //   overrides: {
  //     MuiChip: {
  //       root: {
  //         border: `1px solid rgb(var(--px_color_primary))`,
  //         color: `rgb(var(--px_color_primary))`,
  //         backgroundColor: `rgba(0,0,0,0)`,
  //         '& svg': {
  //           color: `rgb(var(--px_color_primary))`,
  //         },
  //         '& svg:hover': {
  //           color: `rgb(var(--px_color_primary))`,
  //         },
  //         '&:hover': {
  //           backgroundColor: `rgba(var(--px_color_primary), var(--px_alpha_light))`,
  //         },
  //         '&.px-filter-on:hover': {
  //           backgroundColor: `rgba(var(--px_color_primary))`,
  //         },
  //         '&.px-filter-on svg': {
  //           color: `rgb(var(--px_color_text_on_primary))`,
  //         },
  //         '&.px-filter-on': {
  //           color: `rgb(var(--px_color_text_on_primary))`,
  //           backgroundColor: `rgba(var(--px_color_primary))`,
  //         },
  //       },
  //     },
  //     MuiTabs: {
  //       indicator: {
  //         backgroundColor: 'rgb(var(--px_color_primary)) !important',
  //       },
  //     },
  //     MuiTab: {
  //       root: {
  //         '&:hover': {
  //           backgroundColor: `rgba(var(--px_color_primary), var(--px_alpha_lighter)) !important`,
  //         },
  //       },
  //     },
  //     MuiCheckbox: {
  //       root: {
  //         color: `rgb(var(--px_color_primary))`,
  //         '&:hover': {
  //           backgroundColor: 'rgba(var(--px_color_primary), var(--px_alpha_lighter)) !important',
  //         },
  //       },
  //     },
  //     MuiRadio: {
  //       root: {
  //         color: `rgb(var(--px_color_primary))`,
  //         '&:hover': {
  //           backgroundColor: 'rgba(var(--px_color_primary), var(--px_alpha_lighter)) !important',
  //         },
  //       },
  //     },
  //     MuiTextField: {
  //       root: {
  //         '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //           border: `1px solid rgb(var(--px_color_text))`,
  //           color: `rgb(var(--px_color_text))`,
  //         },
  //         '& label.Mui-focused': {
  //           color: `rgb(var(--px_color_text))`,
  //         },
  //       },
  //     },
  //     MuiButton: {
  //       contained: {
  //         color: `rgb(var(--px_color_primary))`,
  //         backgroundColor: `rgba(var(--px_color_primary), var(--px_alpha_light))`,
  //         '&:hover': {
  //           backgroundColor: `rgba(var(--px_color_primary), var(--px_alpha_light))`,
  //         },
  //       },
  //       containedPrimary: {
  //         color: `rgb(var(--px_color_text_on_primary))`,
  //         backgroundColor: `rgb(var(--px_color_primary))`,
  //         '&:hover': {
  //           color: `rgb(var(--px_color_text_on_primary)) !important`,
  //           backgroundColor: `rgb(var(--px_color_primary)) !important`,
  //         },
  //       },
  //       outlined: {
  //         color: `rgb(var(--px_color_primary))`,
  //         borderColor: `rgb(var(--px_color_primary))`,
  //         backgroundColor: `rgba(0,0,0,0)`,
  //         '&:hover': {
  //           backgroundColor: `rgba(0,0,0,0)`,
  //         },
  //       },
  //       root: {
  //         cursor: 'pointer',
  //         color: `rgb(var(--px_color_primary))`,
  //         '&:hover': {
  //           backgroundColor: `rgba(var(--px_color_primary), var(--px_alpha_light)) !important`,
  //         },
  //       },
  //     },
  //   },
  //   zIndex: {
  //     snackbar: 9999,
  //   },
  // });

  return (
    <>
      {/* <MuiThemeProvider theme={prepareTheme}>{props.children}</MuiThemeProvider> */}
      {props.children}
    </>
  );
}

export default Theme;
