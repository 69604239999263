import React from 'react';
import StocklotIcon from './StocklotIcon';

interface IStockLotItemFooter {
  item: any;
}

const StockLotItemFooter = ({ item }: IStockLotItemFooter) => {
  return (
    <>
      <div className="col-sm-12">
        <StocklotIcon item={item} type="LONG" />
      </div>
      <p className="col-sm-12">{item.lotLongDescription}</p>
    </>
  );
};
export default StockLotItemFooter;
