import * as React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton, /*IconButton,*/ Tooltip } from '@material-ui/core';
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BuyOfferDialog from '../BuyOfferDialog/BuyOfferDialog';
import StockAlertDialog from '../../components/StockAlertDialog/StockAlertDialog';

import { I18nContext } from '../../I18n';
import './StockListItem.scss';
import useDataService from '../../hooks/useDataService';
import { TertiaryButton } from '../AtomComponents';
import { eMessageType } from '../../types/IMessageType';
import apiToUrlMap, { formatString } from '../../ApiMapping';
import StockListBuyOffer from './StockListBuyOffer';
import StockListAlert from './StocklistAlert';
import {
  itemRemovedFromCart,
  stocklistItemSelect,
} from '../../redux/actions/analyticsEventActions';
import { useDispatch } from 'react-redux';
import { defaultParticipant } from '../../_lib/constant';
import {
  checkAllItemsHasSpecialPrice,
  getExpandCollapseStockGroupLabel,
  getGroupWarehouseLevel,
  hideStockListGroupPrices,
  hideStockListItemPrices,
  specialPriceCondition,
} from './StockListUtils';
import StockPrice from './StockPrice';
import AutoCheckoutPromptDialog from './AutoCheckoutPromptDialog';
import { IGetShippingPaymentDetails } from '../../types/IBillingShippingTypes';
import AccountSetupPopup from '../AccountSetup/AccountSetupPopup';
import StockLotItemFooter from './StockLot/StockLotItemFooter';
import { getGroupItemDescription } from './StockLot/stockLotUtils';
import { useMutationService } from '../../hooks/useQueryCustomHooks/useDataService';

interface IStockListItem {
  selectedPreferencesOptions: IGetShippingPaymentDetails;
  updateSelectedPreferencesOptions: (
    updatedPreferences: IGetShippingPaymentDetails,
    participantId: any
  ) => Promise<void>;
  openWarehouseGroupDescriptionDialog: (
    participantId: any,
    type: 'info' | 'prompt',
    promptSuccessCb?: any
  ) => () => boolean;
  setupCompleted: boolean;
  [key: string]: any;
}

function StockListItem(props: IStockListItem) {
  const {
    stockListSettings,
    selectedCategory,
    group,
    title,
    offers,
    stockOffersUpdate,
    canUserBuy,
    canUserAlert,
    showBuyOnlineBtn,
    stockAlertsUpdate,
    isPxnr,
    isPxns,
    filtersInUseState,
    menuDataState,
    itemCounts,
    // gradesListObj,
    pxnWarehousesObj,
    openGradePopup,
    setLoading,
    updateSelectedPreferencesOptions,
    selectedPreferencesOptions,
    openWarehouseGroupDescriptionDialog,
    setupCompleted,
  } = props;

  const I18n = React.useContext(I18nContext);
  const dispatch = useDispatch();

  const [expandState, setExpandState] = React.useState(() => {
    return !!group.isStockLotGroup;
  });

  const [isEditMode, SetIsEditMode] = React.useState(false);

  const [isOfferDialog, SetIsOfferDialog] = React.useState(false);

  const [item, setItem] = React.useState(null);

  const [isBuyOfferDialogOpen, setIsBuyOfferDialogOpen] = React.useState(false);

  const [isAccountSetupDialogOpen, setIsAccountSetupDialogOpen] = React.useState(false);

  const [isAutoCheckoutPromptDialogOpen, setIsAutoCheckoutPromptDialogOpen] = React.useState(false);

  const [alertDialogParams, setAlertDialogParams] = React.useState<any>({
    isEditMode: false,
    payload: null,
    isBuyOfferDialogOpen: false,
  });

  const hasMoreThanOneWarehouse =
    stockListSettings?.warehouse?.filter(
      (warehouse: any) => !!menuDataState?.warehouse?.[warehouse]?._totals
    ).length > 1;
  const hasMoreThanOneWarehouseGroup =
    pxnWarehousesObj &&
    Object.keys(pxnWarehousesObj).filter((participantId: any) =>
      isPxnr
        ? participantId in pxnWarehousesObj && !!itemCounts?.[participantId]
        : !!itemCounts?.[participantId]
    ).length > 1;
  // const hasGradeDescription = React.useMemo(() => {
  //   return (
  //     isPxnr &&
  //     isPxns &&
  //     !!gradesListObj?.[+filtersInUseState?.Level0?.warehouseGroup]?.[
  //       group?.groupAttributes?.[stockListSettings?.['groupTopRightAttribute']?.[selectedCategory]]
  //     ]?.pxnrGradeDescription
  //   );
  // }, [
  //   isPxnr,
  //   isPxns,
  //   gradesListObj,
  //   filtersInUseState.Level0.warehouseGroup,
  //   group.groupAttributes,
  //   stockListSettings,
  //   selectedCategory,
  // ]);

  const participantId = +filtersInUseState?.Level0?.warehouseGroup;

  const { hasWarehouseGroupDescription, warehouseLabel } = React.useMemo(() => {
    const hasMoreThanOneGroup = pxnWarehousesObj && Object.keys(pxnWarehousesObj).length > 1;
    const hasWarehouseGroupDescription =
      !!isPxnr &&
      !!pxnWarehousesObj?.[+filtersInUseState?.Level0?.warehouseGroup]?.warehouseGroupDescription;
    let warehouseLabel = '';
    if (hasMoreThanOneGroup) {
      warehouseLabel = `${
        pxnWarehousesObj?.[+filtersInUseState?.Level0?.warehouseGroup]?.warehouseGroupName ?? ''
      } - `;
    }
    warehouseLabel += `${
      pxnWarehousesObj?.[+filtersInUseState?.Level0?.warehouseGroup]?.warehouses?.length > 1
        ? `${
            stockListSettings?.[
              `${stockListSettings?.['groupBottomRightAttribute']?.[selectedCategory]}Labels`
            ]?.[
              group?.groupAttributes?.[
                stockListSettings?.['groupBottomRightAttribute']?.[selectedCategory]
              ]
            ]
              ? stockListSettings?.[
                  `${stockListSettings?.['groupBottomRightAttribute']?.[selectedCategory]}Labels`
                ]?.[
                  group?.groupAttributes?.[
                    stockListSettings?.['groupBottomRightAttribute']?.[selectedCategory]
                  ]
                ]
              : group?.groupAttributes?.[
                  stockListSettings?.['groupBottomRightAttribute']?.[selectedCategory]
                ]
          }`
        : ''
    }`;
    return {
      hasWarehouseGroupDescription,
      warehouseLabel,
    };
  }, [
    filtersInUseState.Level0.warehouseGroup,
    group.groupAttributes,
    isPxnr,
    pxnWarehousesObj,
    selectedCategory,
    stockListSettings,
  ]);

  const openAutoCheckoutPromptDialog = () => setIsAutoCheckoutPromptDialogOpen(true);
  const closeAutoCheckoutPromptDialog = () => setIsAutoCheckoutPromptDialogOpen(false);

  const checkAndOpenAutoCheckoutPromptDialog = () => {
    if (!selectedPreferencesOptions || !isPxns) return;

    const { autoCheckoutEligible, autoCheckoutOption } = selectedPreferencesOptions;
    // open prompt dialog only if
    //   1. auto checkout is eligible,
    //   2. auto checkout is not enabled
    //   3. prompt dialog is not acknowledged.
    if (
      !autoCheckoutEligible ||
      autoCheckoutOption?.eligibilityAcknowledged ||
      autoCheckoutOption?.enabled
    )
      return;

    const participantId = filtersInUseState.Level0.warehouseGroup;
    // check if offer from warehouse group supports auto checkout
    const participantSupportsAutoCheckout = pxnWarehousesObj?.[participantId]?.hasAutoCheckout;
    if (!participantSupportsAutoCheckout) return;

    openAutoCheckoutPromptDialog();
  };

  const acknowledgeAutoCheckoutPromptDialog = async () => {
    const updatedPreferences = { ...selectedPreferencesOptions };
    if (updatedPreferences.autoCheckoutOption) {
      updatedPreferences.autoCheckoutOption.eligibilityAcknowledged = true;
    }
    setLoading(true);
    try {
      await updateSelectedPreferencesOptions(updatedPreferences, participantId);
    } catch (err: any) {
      console.error('Failed to update autocheckout preferences: ', err.message);
    }
    setLoading(false);
    closeAutoCheckoutPromptDialog();
  };

  const openOrClosePopupHandler = (
    isOfferDialog: boolean,
    isEditMode: boolean,
    isOpenPopup: boolean,
    item: any
  ) => {
    const cb = () => openOrClosePopup(isOfferDialog, isEditMode, isOpenPopup, item);
    //for prospects who have not finished the onboarding process, prompt to finish registration process
    if (!setupCompleted) {
      setIsAccountSetupDialogOpen(true);
      return;
    }
    // if called to close popup or editing offer, don't try to open warehouse group description dialog.
    // when opening popup from another popup (isBuyOfferDialogOpen), skip opening warehouse group description dialog.
    if (!isOpenPopup || isEditMode || isBuyOfferDialogOpen) {
      cb();
      return;
    }
    // opens warehouse group description dialog if not acknowledged.
    // returns true if dialog was opened.
    const isDialogOpened = openWarehouseGroupDescriptionDialog(participantId, 'prompt', cb)();
    if (!isDialogOpened) {
      cb();
    }
  };

  const openOrClosePopup = (
    isOfferDialog: boolean,
    isEditMode: boolean,
    isOpenPopup: boolean,
    item: any
  ) => {
    SetIsOfferDialog(isOfferDialog);
    SetIsEditMode(isEditMode);
    setItem(item);
    setIsBuyOfferDialogOpen(isOpenPopup);
  };

  function closeAccountSetupPopup() {
    setIsAccountSetupDialogOpen(false);
  }

  const openOrCloseAlertDialog = (
    isEditMode: boolean,
    payload: any,
    isBuyOfferDialogOpen: boolean
  ) => {
    setAlertDialogParams({
      isEditMode,
      payload,
      isBuyOfferDialogOpen,
    });

    setIsBuyOfferDialogOpen(isBuyOfferDialogOpen);

    if (payload === null) setIsBuyOfferDialogOpen(isBuyOfferDialogOpen);
    else setIsBuyOfferDialogOpen(false);
  };

  const { mutateAsync: setOrEditAlertMutate } = useMutationService({
    onSuccess: (_, params) => {
      const { itemNumberWarehouse, payload, editMode } = params.variables;

      stockAlertsUpdate(itemNumberWarehouse, payload);
      setAlertDialogParams({ isEditMode: false, payload: null, buyOfferPayload: false });
      setIsBuyOfferDialogOpen(isBuyOfferDialogOpen);

      openSnackBar(
        !editMode
          ? I18n.successfullyCreatedTheAlert || 'Successfully created the stock alert'
          : I18n.successfullyUpdatedTheAlert || 'Successfully updated the stock alert',
        eMessageType.success
      );
    },
  });

  const setOrEditAlert = async (
    itemNumberWarehouse: string,
    isEditMode: boolean,
    payload: any,
    isBuyOfferDialogOpen: boolean
  ) => {
    const { quantity, availableQuantity } = payload;
    let httpVerb,
      path,
      httpPayload: any = {
        quantity,
        quantityAvailable: availableQuantity,
      };

    if (isEditMode) {
      httpVerb = 'PUT';
      path = formatString(apiToUrlMap.stockAlertQuantity, {
        itemNumberWarehouse: btoa(itemNumberWarehouse),
      });
    } else {
      httpVerb = 'POST';
      path = `/px-api-gateway/stocklist/alert/item`;
      httpPayload.itemNumberWarehouse = itemNumberWarehouse;
    }

    const headers: any = {};
    if (
      isPxns &&
      stockListSettings?.pxParticipantId &&
      stockListSettings.pxParticipantId !== defaultParticipant
    ) {
      headers['pxns-participant-id'] = stockListSettings.pxParticipantId;
    }

    try {
      await setOrEditAlertMutate({
        variables: { itemNumberWarehouse, payload, editMode: isEditMode },
        url: path,
        httpMethod: httpVerb,
        additionalParams: {
          body: httpPayload,
          headers,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { openSnackBar } = useDataService();

  const { mutateAsync: makeOfferOrBuyMutate } = useMutationService({
    onSuccess: (response: any, params) => {
      let newOffers = {};
      const { item, payload } = params.variables;

      if (!isEditMode && response && response.stocklistOffer) {
        openSnackBar(
          <label>
            {I18n.addedToCart || 'Added to the Cart'} &emsp;
            <strong>{item.groupDescription}</strong>&nbsp;
            {item.groupItemDescription}
          </label>,
          eMessageType.success
        );
        newOffers = {
          ...offers,
          [item.itemNumberWarehouse]: response,
        };
        stockOffersUpdate(newOffers);
      } else if (response && response.stocklistOffer) {
        openSnackBar(
          <label>
            {I18n.successUpdateCart || 'Successfully updated to the Cart'} &emsp;
            <strong>{item.groupDescription}</strong>
            {item.groupItemDescription}
          </label>,
          eMessageType.success
        );
        newOffers = {
          ...offers,
          [item.itemNumberWarehouse]: response,
        };
        stockOffersUpdate(newOffers);
      }
      setItem(null);
      setIsBuyOfferDialogOpen(false);
      const isBuyAtList = item.offerPrice === payload.offerPrice;
      if (!isEditMode && !isBuyAtList) checkAndOpenAutoCheckoutPromptDialog();
    },
    onSettled: () => setLoading(false),
  });

  const makeOfferOrBuy = async (
    isEditMode: boolean,
    item: any,
    payload: any,
    isOfferDialog: boolean
  ) => {
    setLoading(true);
    const body = payload;
    let endpoint = isEditMode
      ? `/px-api-gateway/stocklist/offer/${item.offerId}`
      : '/px-api-gateway/stocklist/offer';

    if (isPxns) {
      endpoint = isEditMode
        ? `${apiToUrlMap.pxnrStockOffer}/${item.offerId}`
        : apiToUrlMap.pxnrStockOffer;
    }

    try {
      await makeOfferOrBuyMutate({
        variables: { item, payload },
        url: endpoint,
        httpMethod: isEditMode ? 'PUT' : 'POST',
        additionalParams: isPxns
          ? {
              body,
              headers: { 'pxns-participant-id': stockListSettings?.pxParticipantId },
            }
          : { body },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { mutateAsync: deleteOfferMutate } = useMutationService({
    onSuccess: (data, params) => {
      const item = params.variables;

      if (!data || !!!Object?.keys(data ?? {})?.length) {
        const newOfferState = { ...offers };
        delete newOfferState[item.itemNumberWarehouse];
        setItem(null);
        setIsBuyOfferDialogOpen(false);
        stockOffersUpdate(newOfferState);
        dispatch(itemRemovedFromCart([item]));
        openSnackBar(
          I18n.successRemoveFromCart || 'Successfully removed from the cart',
          eMessageType.success
        );
      }
    },
    onSettled: () => setLoading(false),
  });

  const deleteOffer = async (item: any) => {
    setLoading(true);
    try {
      await deleteOfferMutate({
        variables: item,
        httpMethod: 'DELETE',
        url: isPxns
          ? `${apiToUrlMap.pxnrStockOffer}/${item.offerId}`
          : `/px-api-gateway/stocklist/offer/${item.offerId}`,
        additionalParams: {
          headers: isPxns ? { 'pxns-participant-id': stockListSettings?.pxParticipantId } : {},
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { fullWarehouseLabel, trimmedWarehouseLabel } = getGroupWarehouseLevel(
    group.groupAttributes,
    stockListSettings,
    selectedCategory,
    warehouseLabel,
    hasMoreThanOneWarehouse,
    isPxnr
  );

  const allItemsHasListPrice = checkAllItemsHasSpecialPrice(group.items);

  return (
    <>
      {group && group.title && stockListSettings ? (
        <div
          className={`grid-x cell small-12 px-item px-box ${
            expandState ? 'expanded-group' : 'collapsed-group'
          }`}
          data-id="itemGroups"
        >
          <div className="grid-x cell small-12 px-item-description">
            <h4 className="cell auto px-item-title">{title.split('___')[0]}</h4>
            <div className="cell shrink px-item-code padding-0" data-id="itemGrade">
              <h4>
                {stockListSettings[
                  `${stockListSettings['groupTopRightAttribute'][selectedCategory]}Labels`
                ] &&
                stockListSettings[
                  `${stockListSettings['groupTopRightAttribute'][selectedCategory]}Labels`
                ][
                  group.groupAttributes[
                    stockListSettings['groupTopRightAttribute'][selectedCategory]
                  ]
                ]
                  ? stockListSettings[
                      `${stockListSettings['groupTopRightAttribute'][selectedCategory]}Labels`
                    ][
                      group.groupAttributes[
                        stockListSettings['groupTopRightAttribute'][selectedCategory]
                      ]
                    ]
                  : group.groupAttributes[
                      stockListSettings['groupTopRightAttribute'][selectedCategory]
                    ]}
              </h4>
              {stockListSettings.showGradeIcon &&
                stockListSettings?.groupTopRightAttribute?.[selectedCategory] && (
                  <IconButton
                    className="px-grade-info px-color-secondary"
                    onClick={() =>
                      openGradePopup(
                        group.groupAttributes[
                          stockListSettings['groupTopRightAttribute'][selectedCategory]
                        ]
                      )
                    }
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                )}
            </div>
          </div>
          {!expandState && (
            <div className="cell small-12 px-item-info">
              <div className="grid-x px-items-list px-items-list-header px-text-description">
                <div className="px-item-num small-2 medium-1 padding-left-1 margin-right-1">
                  <span>{I18n.items || 'Items'}</span>
                </div>
                <div className="px-item-qty small-3 medium-1 text-right margin-right-1">
                  <span>{I18n.quantity || 'Quantity'}</span>
                </div>
                <div
                  className={`px-item-price ${
                    specialPriceCondition(group.specialPrice, group.minPrice)
                      ? 'small-3 medium-2 margin-left-1'
                      : 'small-2 medium-1 text-right margin-right-1'
                  } `}
                >
                  {!hideStockListGroupPrices(stockListSettings, group.minPrice) && (
                    <span>{I18n.price || 'Price'}</span>
                  )}
                </div>
              </div>
              <div className="grid-x px-items-list px-items-list-content">
                <div className="px-item-num small-2 medium-1 margin-right-1 padding-left-1">
                  <span>{group.countOfItems}</span>
                </div>
                <div className="px-item-qty small-3 medium-1 text-right margin-right-1">
                  <span>
                    {stockListSettings['maxItemQuantityToBeDisplayed']
                      ? group.quantityAvailable > stockListSettings['maxItemQuantityToBeDisplayed']
                        ? `${stockListSettings['maxItemQuantityToBeDisplayed']}+`
                        : group.quantityAvailable
                      : group.quantityAvailable}
                  </span>
                </div>
                <div
                  className={`px-item-price ${
                    specialPriceCondition(group.specialPrice, group.minPrice)
                      ? 'small-3 medium-2 text-right margin-left-1'
                      : 'small-2 medium-1 text-right margin-right-1'
                  } `}
                >
                  {!hideStockListGroupPrices(stockListSettings, group.minPrice) && (
                    <span>
                      <StockPrice
                        listPrice={group.minPrice}
                        showMinPrice={!expandState}
                        showMinPriceSymbol={group.showMinPriceSymbol}
                        specialPrice={group.specialPrice}
                        variant="GROUP"
                        allItemsHasListPrice={allItemsHasListPrice}
                      />
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          {expandState && (
            <div className="cell small-12 px-item-cart">
              <div className="grid-x px-items-list-header px-text-description">
                <div className="cell small-6 medium-4 large-4">
                  <span className="padding-left-20">{I18n.item || 'Item'}</span>
                </div>
                <div className="cell small-3 medium-1 large-2 text-right">
                  <span>{I18n.quantity || 'Quantity'}</span>
                </div>

                <div className="cell small-3 medium-2 large-2 text-right">
                  {/* since we've radio category filter and we're selecting a category all the time */}
                  {!hideStockListItemPrices(stockListSettings, group.items[0]?.category) && (
                    <span>{I18n.price || 'Price'}</span>
                  )}
                </div>
                <div className="cell small-12 medium-5 large-4 hide-for-sm-only"></div>
              </div>
              {group.items.map((item: any) => {
                const key = `${item.itemNumberWarehouse || item.itemNumber + item.warehouse}`;
                const { groupDescription } = group;
                return (
                  <div
                    className="grid-x px-item-item hover-bg px-items-list-content"
                    key={key}
                    data-id={key}
                  >
                    <p className="cell small-6 medium-4 large-4 padding-left-20" data-id="item">
                      <span>{getGroupItemDescription(item, group.isStockLotGroup)}</span>
                      <span>{'   '}</span>
                      <span className="text-muted">{`[${item.itemNumber}]`}</span>
                    </p>
                    <div
                      className="cell small-3 large-2 medium-1 thin-line-height text-right"
                      data-id="quantity"
                    >
                      {stockListSettings['maxItemQuantityToBeDisplayed']
                        ? item.quantityAvailable > stockListSettings['maxItemQuantityToBeDisplayed']
                          ? `${stockListSettings['maxItemQuantityToBeDisplayed']}+`
                          : item.quantityAvailable
                        : item.quantityAvailable}
                    </div>
                    <div
                      className="cell small-3 large-2 medium-2 thin-line-height text-right px-align-middle flex-end"
                      data-id="price"
                    >
                      {!hideStockListItemPrices(stockListSettings, item.category) && (
                        <StockPrice
                          listPrice={item.listPrice}
                          showMinPrice={expandState}
                          specialPrice={item.specialPrice}
                        />
                      )}
                    </div>
                    <div className="cell small-12 medium-5 large-4 text-right thin-line-height px-item-cart-btns relative">
                      <StockListBuyOffer
                        offers={offers}
                        item={item}
                        groupDescription={groupDescription}
                        isPxns={isPxns}
                        openOrClosePopup={openOrClosePopupHandler}
                        settings={stockListSettings}
                        canUserBuy={canUserBuy}
                        canOffer={group.isOfferAccepted}
                        showBuyOnlineBtn={showBuyOnlineBtn}
                        categorySaleOption={stockListSettings.categorySaleOption}
                      />
                      <StockListAlert
                        canUserAlert={canUserAlert}
                        openOrCloseAlertDialog={openOrCloseAlertDialog}
                        stockListSettings={stockListSettings}
                        item={item}
                      />
                    </div>
                    {!!group.isStockLotGroup && (
                      <div className="cell small-12 padding-left-20 padding-right-20">
                        <StockLotItemFooter item={item} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <span>
            {hasMoreThanOneWarehouse &&
              hasWarehouseGroupDescription &&
              hasMoreThanOneWarehouseGroup &&
              !!group.groupAttributes[
                stockListSettings['groupBottomRightAttribute'][selectedCategory]
              ] && (
                <IconButton
                  className="px-item-warehouse-info cell small-1 px-color-secondary"
                  onClick={openWarehouseGroupDescriptionDialog(
                    filtersInUseState?.Level0?.warehouseGroup,
                    'info'
                  )}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              )}
          </span>
          <TertiaryButton
            data-id="expandButton"
            className={`cell small-12 px-item-more ${
              !!group.isStockLotGroup && 'px-cursor-default'
            }`}
            classes={{ label: 'grid-x' }}
            disableRipple={!!group.isStockLotGroup}
            disableFocusRipple={!!group.isStockLotGroup}
            onClick={() => {
              // if stock lot group there's nothing to expand
              if (group.isStockLotGroup) return;
              setExpandState((prev: boolean) => {
                if (!prev) dispatch(stocklistItemSelect(props?.group?.items ?? {}));
                return !expandState;
              });
            }}
          >
            <span className="px-open-stock-list-group-container small-7">
              {!group.isStockLotGroup && (
                <span className="px-open-stock-list-group">
                  {!!showBuyOnlineBtn && (
                    <span>
                      {getExpandCollapseStockGroupLabel(I18n, group.isOfferAccepted, expandState)}
                    </span>
                  )}
                  {expandState ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
              )}
            </span>
            <span
              className="px-item-warehouse padding-0 small-5 text-right"
              data-id="itemGroupWarehouse"
            >
              {trimmedWarehouseLabel && (
                <Tooltip
                  title={fullWarehouseLabel}
                  TransitionProps={{ timeout: 0 }}
                  classes={{
                    tooltip: 'px-stocklist-group-warehouse-tooltip',
                  }}
                  interactive
                >
                  <h4
                    style={
                      hasWarehouseGroupDescription && hasMoreThanOneWarehouseGroup
                        ? { lineHeight: '25px', paddingRight: '30px' }
                        : { lineHeight: '25px' }
                    }
                  >
                    {trimmedWarehouseLabel}
                  </h4>
                </Tooltip>
              )}
            </span>
          </TertiaryButton>
        </div>
      ) : (
        <></>
      )}
      <AutoCheckoutPromptDialog
        isDialogOpen={isAutoCheckoutPromptDialogOpen}
        closeDialog={closeAutoCheckoutPromptDialog}
        acknowledgeAutoCheckoutPromptDialog={acknowledgeAutoCheckoutPromptDialog}
        warehouseGroup={participantId || null}
      />
      {isBuyOfferDialogOpen && item && (
        <BuyOfferDialog
          isOfferDialog={isOfferDialog}
          isOpenPopup={true}
          isEditMode={isEditMode}
          openOrClosePopup={openOrClosePopupHandler}
          item={item}
          makeOfferOrBuy={makeOfferOrBuy}
          canOffer={group.isOfferAccepted}
          deleteOffer={deleteOffer}
          openOrCloseAlertDialog={openOrCloseAlertDialog}
          stockListSettings={stockListSettings}
        />
      )}
      {isAccountSetupDialogOpen && (
        <AccountSetupPopup
          isDialogOpen={isAccountSetupDialogOpen}
          closeDialog={closeAccountSetupPopup}
        />
      )}
      {Boolean(alertDialogParams['payload']) && (
        <StockAlertDialog
          title={I18n.stockAlert || 'Stock Alert'}
          isDialogOpen={true}
          closeDialog={openOrCloseAlertDialog}
          stockAlert={alertDialogParams['payload']}
          setOrEditAlert={setOrEditAlert}
          isEditMode={alertDialogParams['isEditMode']}
          isBuyOfferDialogOpen={alertDialogParams['isBuyOfferDialogOpen']}
          stockListSettings={stockListSettings}
        />
      )}
    </>
  );
}

export default StockListItem;
