export const READY_TO_CHECKOUT = 'READY_TO_CHECKOUT';
export const AWAITING_TENANT_REVIEW = 'AWAITING_TENANT_REVIEW';
export const AWAITING_BUYER_REVIEW = 'AWAITING_BUYER_REVIEW';
export const READY_FOR_AUTO_CHECKOUT = 'READY_FOR_AUTO_CHECKOUT';
export const OUT_OF_STOCK = 'OUT_OF_STOCK';

export const PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE';
export const BUY_AT_LIST = 'BUY_AT_LIST';
export const ACCEPTED = 'ACCEPTED';
export const EXPIRED = 'EXPIRED';
export const NOT_ACCEPTED = 'NOT_ACCEPTED';
export const PRICE_DROPPED = 'PRICE_DROPPED';
export const COUNTERED = 'COUNTERED';
export const CHECKOUT_HOLD_ORDER_UNTIL_QUOTE_UPDATE = 'CHECKOUT_HOLD_ORDER_UNTIL_QUOTE_UPDATE';
export const ALL_VIA_PHONEX_AND_HAS_EXTERNAL_SYSTEM = 'ALL_VIA_PHONEX_AND_HAS_EXTERNAL_SYSTEM';

// aco reason code
export const BUYER_CHECKOUT_LIMIT_EXCEEDS = 'BUYER_CHECKOUT_LIMIT_EXCEEDS';
export const PXNR_CHECKOUT_LIMIT_EXCEEDS = 'PXNR_CHECKOUT_LIMIT_EXCEEDS';

export const CART_TABS = [READY_TO_CHECKOUT, AWAITING_BUYER_REVIEW, AWAITING_TENANT_REVIEW];
export const OFFER_TABS = [AWAITING_BUYER_REVIEW, AWAITING_TENANT_REVIEW];

export const offerStatusChipMap: any = {
  COUNTERED: 'countered',
  PRICE_DROPPED: 'pricedrop',
  NOT_ACCEPTED: 'rejected',
  PENDING_ACCEPTANCE: 'offered',
  ACCEPTED: 'accepted',
  EXPIRED: 'expired',
  BUY_AT_LIST: 'buyatlist',
  OUT_OF_STOCK: 'outofstock',
  READY_FOR_AUTO_CHECKOUT: 'autocheckout',
};

const offerStatusFilterChipMap: any = {
  COUNTERED: 'Countered Offers',
  PRICE_DROPPED: 'Price Drops',
  NOT_ACCEPTED: 'Rejected Offers',
  PENDING_ACCEPTANCE: 'Offered',
  ACCEPTED: 'Accepted Offers',
  EXPIRED: 'Expired Offers',
  BUY_AT_LIST: 'At List Price',
  READY_FOR_AUTO_CHECKOUT: 'Accepted - Auto-Checkout',
};

export const offerStatusLabelMap: any = {
  PENDING_ACCEPTANCE: 'Offered',
  NOT_ACCEPTED: 'Rejected',
  ACCEPTED: 'Accepted',
  COUNTERED: 'Countered',
  PRICE_DROPPED: 'Price Drop',
  EXPIRED: 'Expired',
  BUY_AT_LIST: 'Buy At List',
  OUT_OF_STOCK: 'Out of Stock',
  READY_FOR_AUTO_CHECKOUT: 'Accepted - Auto-Checkout',
};

const offerStatusExtraInfoMap: any = {
  PENDING_ACCEPTANCE: '',
  NOT_ACCEPTED: 'Your offer of ${1} was Rejected',
  ACCEPTED:
    'Your offer of ${1} was accepted. New Price is valid for 1 order with minimum quantity of ${2}, expires in ${3}',
  COUNTERED:
    'Your offer of ${1} was countered. Seller Counter of ${2} is valid for 1 order with minimum quantity of ${3}, expires in ${4}',
  PRICE_DROPPED:
    'The list price was lowered and is now lower than your offer. You can checkout now at this new list price',
  EXPIRED: 'Your offer of ${1} expired',
  BUY_AT_LIST: '',
  OUT_OF_STOCK: '',
  READY_FOR_AUTO_CHECKOUT: '',
};

export const offerStatusLabelI18nKey: any = {
  PENDING_ACCEPTANCE: 'offered',
  NOT_ACCEPTED: 'rejected',
  ACCEPTED: 'accepted',
  COUNTERED: 'countered',
  PRICE_DROPPED: 'priceDropped',
  EXPIRED: 'expired',
  BUY_AT_LIST: 'buyAtList',
  OUT_OF_STOCK: 'outOfStock',
  READY_FOR_AUTO_CHECKOUT: 'readyForAutoCheckout',
};

const offerStatusExtraInfoI18nKey: any = {
  PENDING_ACCEPTANCE: 'offeredExtraInfo',
  NOT_ACCEPTED: 'rejectedExtraInfo',
  ACCEPTED: 'acceptedExtraInfo',
  COUNTERED: 'counteredExtraInfo',
  PRICE_DROPPED: 'priceDroppedExtraInfo',
  EXPIRED: 'expiredExtraInfo',
  BUY_AT_LIST: 'buyAtListExtraInfo',
  OUT_OF_STOCK: 'outOfStockExtraInfo',
  READY_FOR_AUTO_CHECKOUT: 'readyForAutoCheckoutInfo',
};

const offerStatusFilterChipI18nKey: any = {
  PENDING_ACCEPTANCE: 'offered',
  NOT_ACCEPTED: 'rejectedOffers',
  ACCEPTED: 'acceptedOffers',
  COUNTERED: 'counteredOffers',
  PRICE_DROPPED: 'priceDrops',
  EXPIRED: 'expiredOffers',
  BUY_AT_LIST: 'buyAtList',
  OUT_OF_STOCK: 'outOfStock',
  READY_FOR_AUTO_CHECKOUT: 'readyForAutoCheckout',
};

export const getOfferStatusLabel = (I18n: any, offerStatus: any) => {
  return I18n[offerStatusLabelI18nKey[offerStatus]] || offerStatusLabelMap[offerStatus];
};

export const getofferStatusExtraInfo = (I18n: any, offerStatus: any) => {
  return I18n[offerStatusExtraInfoI18nKey[offerStatus]] || offerStatusExtraInfoMap[offerStatus];
};

export const getofferStatusFilterChipLabel = (I18n: any, offerStatus: any) => {
  return I18n[offerStatusFilterChipI18nKey[offerStatus]] || offerStatusFilterChipMap[offerStatus];
};
