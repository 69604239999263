import * as types from '../actions/actionTypes';
import { isAllViaPhonexAndHasExternalSystem } from '../../_lib/rules';
export const processReduxSalesOrderSettings = (settings: any) => {
  Object.keys(settings).forEach((participantId) => {
    settings[participantId].isExternalSystemManualSync = isAllViaPhonexAndHasExternalSystem(
      settings[participantId]?.salesOrderUpdateMethod
    );
    settings[participantId].singleButtonOrderFlow = !!settings[participantId].fulfillmentSettings
      .singleButtonOrderFlow;
    settings[participantId].enableOnholdForManualSyncTenant = !!settings[participantId]
      .fulfillmentSettings.enableOnholdForManualSyncTenant;
  });
  return settings;
};

export default function salesOrderReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.SALES_ORDER_SETTINGS_SET:
      return {
        ...state,
        ...action.settings,
      };
    default:
      return state;
  }
}
