import { IPOEReduxState, IPOEServerResponse } from '../../types/POETypes';
import { getPendingProofDocuments } from '../../_lib/util';
import * as types from '../actions/actionTypes';

export default function userReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.USER_SET:
      const { isRoleBuyer, requiresAccountSetup, gracePeriodBuyer } = action.user;
      return {
        ...state,
        ...action.user,
        isSignedIn: isRoleBuyer && !requiresAccountSetup && !gracePeriodBuyer,
        pass: '*',
        authorities: {},
      };
    case types.USER_STATE_SET:
      return { ...state, ...action.state };
    case types.SET_POE_DATA:
      const poeServerResponse: IPOEServerResponse = action.payload;
      // only keeping rejected or pending documents (status: 'REJECTED' or null)
      const poeDocuments = getPendingProofDocuments(poeServerResponse.documents);
      // open dialog based on poe data
      const poeData: IPOEReduxState = {
        ...state.poeData,
        ...poeServerResponse,
        documents: poeDocuments,
        isOpen: poeDocuments.length > 0,
      };
      return { ...state, poeData };
    case types.OPEN_POE_DIALOG:
      return { ...state, poeData: { ...state.poeData, isOpen: true } };
    case types.CLOSE_POE_DIALOG:
      return { ...state, poeData: { ...state.poeData, isOpen: false } };
    case types.USER_AUTHORITIES_SET:
      return { ...state, authorities: action.authorities };
    default:
      return state;
  }
}
