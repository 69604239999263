import React from 'react';
import { FilterInUserType } from '../../types/StockListTypes';
import { I18nContext } from '../../I18n';
import { getAttrLabel } from '../../_lib/lib';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { PrimaryRadio } from '../AtomComponents';

interface ICategoryFilter {
  filtersInUseState: FilterInUserType;
  menuDataState: any;
  stockListSettings: any;
  curTenantSettings: any;
  handleChange: (e: any, filterLevel: number, filterName: string, isReset?: boolean) => void;
}

const CategoryFilter = ({
  filtersInUseState,
  menuDataState,
  stockListSettings,
  curTenantSettings,
  handleChange,
}: ICategoryFilter) => {
  const I18n = React.useContext(I18nContext);

  return (
    <>
      <h3>{I18n.categories || getAttrLabel(curTenantSettings, 'category', 'Categories')}</h3>
      <RadioGroup
        aria-label={I18n.category || getAttrLabel(curTenantSettings, 'category', 'Category')}
        data-id="category"
        name="category"
        value={filtersInUseState.Level0.category}
        onChange={(e: any) => {
          handleChange(e, 0, 'category');
        }}
      >
        {Object.keys(menuDataState?.['categories'] ?? {}).map((category: any) => {
          const categoryCount =
            filtersInUseState.Level0.category === category
              ? menuDataState?._totals?.itemsCount
              : menuDataState['categories'][category]._totals;

          return (
            (categoryCount > 0 || filtersInUseState.Level0.category === category) && (
              <FormControlLabel
                key={category}
                data-id={category}
                value={category}
                control={<PrimaryRadio />}
                disabled={categoryCount === 0}
                label={
                  (I18n[stockListSettings['categoryLabels'][category]]
                    ? I18n[stockListSettings['categoryLabels'][category]]
                    : `${stockListSettings['categoryLabels'][category]}`) + ` (${categoryCount})`
                }
              />
            )
          );
        })}
      </RadioGroup>
    </>
  );
};

export default CategoryFilter;
