import React from 'react';

import GradeMixChart from './GradeMixChart';

import { GradeMix } from '../../types/StockListTypes';

interface IGradeMixChartPreview {
  gradeMixChart: GradeMix[];
}

function GradeMixChartPreview({ gradeMixChart }: IGradeMixChartPreview) {
  return (
    gradeMixChart && (
      <div className="cell small-12 grid-x margin-top-2 grade-comparison-mix-block">
        <div className="grade-comparison-mix-list">
          {gradeMixChart.map(({ label, value }) => (
            <React.Fragment key={label}>
              <div className="mix-label">
                <span>{label}</span>
                <span>:</span>
              </div>
              <span className="mix-percent">{value}%</span>
            </React.Fragment>
          ))}
        </div>
        <div className="cell small-12 medium-6 grade-comparison-chart">
          <GradeMixChart gradeMixChart={gradeMixChart} />
        </div>
      </div>
    )
  );
}

export default GradeMixChartPreview;
