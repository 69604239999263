import * as types from '../actions/actionTypes';

export default function bannerReducer(state: Array<any> = [], action: any) {
  switch (action.type) {
    case types.BANNER_PAYLOAD_SET:
      return [...state, ...action.payload];
    case types.BANNER_INACTIVE:
      const bannerPayload = [...state];
      bannerPayload.map((details) => (details.isBannerActive = false));
      return [...state, ...bannerPayload];
    default:
      return state;
  }
}
