import React from 'react';
import { sanitizeHTML } from '../../_lib/util';
import { PrimaryIconButton } from '../AtomComponents';

interface IAuthTopMenu {
  configState: any;
  userData: any;
  openMenu: any;
}

const AuthTopMenu = (props: IAuthTopMenu) => {
  const topMenuHeaderRef = React.useRef<any>();

  React.useEffect(() => {
    if (props.configState.site_header && topMenuHeaderRef.current) {
      topMenuHeaderRef.current.innerHTML = sanitizeHTML(props.configState.site_header);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configState.site_header]);

  return (
    <>
      <div className="px-top-menu-auth-header-container">
        <div className="px-configurable-header" ref={topMenuHeaderRef}></div>
        {props.userData && (
          <PrimaryIconButton onClick={props.openMenu} className="px-user-initials">
            {props.userData.firstName?.[0]?.toUpperCase() || '\u0020'}
            {props.userData.lastName?.[0]?.toUpperCase() || '\u0020'}
          </PrimaryIconButton>
        )}
      </div>
    </>
  );
};
export default AuthTopMenu;
