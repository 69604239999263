import React, { useEffect } from 'react';
import { useGetQueryCacheData } from '../../hooks/useQueryCustomHooks/useGetQueryCacheData';
import { publicApiMapping } from '../../hooks/useQueryCustomHooks/usePublicQueryStates';
import './Logo.scss';

function Logo() {
  const configState = useGetQueryCacheData<any>(publicApiMapping.config);

  useEffect(() => {
    if (!configState?.topMenuLogoImageStyles) return;
    const logo = document.getElementById('pxTopMenuLogoImage');
    if (!logo) return;
    logo.setAttribute('style', configState.topMenuLogoImageStyles);
  }, [configState]);

  if (configState.logoSmall) {
    return (
      <div className="px-c-logo">
        {configState.logoSmall && (
          <img src={`${configState.logoSmall}`} loading="lazy" alt="Logo" id="pxTopMenuLogoImage" />
        )}
        <div className="px-logoReferer"></div>
      </div>
    );
  } else {
    return <div className="px-c-logo">Logo</div>;
  }
}

export default Logo;
