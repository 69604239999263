import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueries,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { API, Auth } from 'aws-amplify';
import { useContext } from 'react';
import apiToUrlMap from '../../ApiMapping';
import { useErrorHandlerContext } from '../../components/ErrorHandlers/ErrorHandler';
import { I18nContext } from '../../I18n';
import { eMessageType, IMessageType } from '../../types/IMessageType';
import { logOutTheUser, triggerBotActivityEvent } from '../../_lib/util';
import { unauthorizedUserErrorHandler } from '../useDataService';
import { downloadFile, sanitizeString } from '../../_lib/lib';

export const pathOverrideKeys = {
  [apiToUrlMap.pxnStockSettings]: 'STOCK_LIST_SETTINGS',
  [apiToUrlMap.stockListSettings]: 'STOCK_LIST_SETTINGS',
  [apiToUrlMap.pxnOrderSettings]: 'SALES_ORDER_SETTINGS',
  [apiToUrlMap.orderSettings]: 'SALES_ORDER_SETTINGS',
};

const urlConfig = () => {
  const pxConfig = localStorage.getItem('PxConfig') || '{"tenantApiName":"tenantSpecificEndpoint"}';
  const configJSON = JSON.parse(pxConfig);
  const modules =
    typeof configJSON.modules === 'string' ? JSON.parse(configJSON.modules) : configJSON.modules;
  let apiName = modules?.phonex_saas_mfe_tenant?.tenantApiName,
    tenantEndPoint = modules?.phonex_saas_mfe_tenant?.tenantApiEndPoint;
  if (!apiName && configJSON?.tenantApiName) apiName = configJSON.tenantApiName;
  if (!tenantEndPoint && configJSON?.tenantApiEndPoint)
    tenantEndPoint = configJSON.tenantApiEndPoint;
  return { apiName, tenantEndPoint };
};

let { apiName, tenantEndPoint } = urlConfig();

interface queryParams {
  apiNameKey?: string;
  httpMethod?: string;
  additionalParams?: any;
  customErrorMessage?: string;
  sendErrorCode?: boolean;
  manualCatchHandle?: boolean;
  transformData?: (data: any) => any;
}

interface queryMeta extends queryParams {
  I18n: any;
  openSnackBar: (message: any, type: IMessageType) => any;
  url: string;
}

interface IuseDataService extends UseQueryOptions {
  url: string;
  overrideQueryKey?: string;
  queryParams?: queryParams;
}

interface IuseMutationService extends UseMutationOptions {
  overrideQueryKey?: string;
  queryParams?: queryParams;
  url?: string;
}

interface mutationVariables extends queryParams {
  url?: string;
  variables?: any;
}

interface IfileUploadMutationVariables {
  file?: any;
  path?: string;
  headers?: Array<IFileUploadHeader>;
  contentType?: string;
  absolute?: boolean;
  additionalParams?: fileUploadAdditionalParams;
}

interface IuseFileUploadMutationService
  extends UseMutationOptions<any, any, IfileUploadMutationVariables, any>,
    IfileUploadMutationVariables {}

interface IFileUploadHeader {
  header: string;
  value: string;
}

interface fileUploadAdditionalParams {
  fileName: string;
}

interface IExportData extends IuseDataService {
  fileName?: string;
}

// interface IFetchUrl {
//   queryKey: string;
//   meta: {
//     httpMethod: string;
//     path: string;
//     additionalParams: any;
//     customErrorMessage?: string;
//     sendErrorCode?: boolean;
//     manualCatchHandle?: boolean;
//   };
// }

const fetchUrl: <T>({ queryKey, meta }: any) => Promise<T> = async ({ _, meta }) => {
  const {
    apiNameKey,
    httpMethod = 'GET',
    additionalParams = {},
    customErrorMessage,
    sendErrorCode,
    manualCatchHandle = false,
    I18n,
    openSnackBar,
    transformData,
    url,
  }: queryMeta = meta;
  let result: any;
  try {
    const baseUrl = apiNameKey ?? apiName;
    switch (httpMethod.toUpperCase()) {
      case 'GET': {
        result = await API.get(baseUrl, url, additionalParams);
        break;
      }
      case 'POST': {
        result = await API.post(baseUrl, url, additionalParams);
        break;
      }
      case 'PUT': {
        result = await API.put(baseUrl, url, additionalParams);
        break;
      }
      case 'PATCH': {
        result = await API.patch(baseUrl, url, additionalParams);
        break;
      }
      case 'DELETE': {
        result = await API.del(baseUrl, url, additionalParams);
        break;
      }
      default:
        return null;
    }
  } catch (error: any) {
    return await handleError(I18n, openSnackBar)(
      error,
      customErrorMessage,
      sendErrorCode,
      manualCatchHandle
    );
  }

  //if (!result) openSnackBar('Internal Server Error', 'error');

  result = transformData ? await transformData(result) : result;
  return result;
};

interface IfileUploadProps {
  file: any;
  path: string;
  headers?: Array<IFileUploadHeader>;
  contentType?: string;
  absolute?: boolean;
  additionalParams?: fileUploadAdditionalParams;
}

const fileUpload = ({
  file,
  path,
  headers,
  contentType,
  absolute,
  additionalParams,
}: IfileUploadProps) => {
  let urlConfiguration = urlConfig();
  apiName = urlConfiguration.apiName;
  tenantEndPoint = urlConfiguration.tenantEndPoint;

  return new Promise(async (resolve: any, reject: any) => {
    try {
      var xmlHttpRequest = new XMLHttpRequest();
      xmlHttpRequest.open('POST', absolute ? path : tenantEndPoint + path, true);
      let updatedContentType = undefined;
      if (!absolute && !contentType)
        updatedContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      else {
        updatedContentType = contentType;
      }
      if (updatedContentType) xmlHttpRequest.setRequestHeader('Content-Type', updatedContentType);

      xmlHttpRequest.setRequestHeader(
        'Authorization',
        `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      );
      const fileName = additionalParams?.fileName ?? sanitizeString(file.name);
      xmlHttpRequest.setRequestHeader('file-name', fileName);
      if (headers) {
        headers.forEach((val) => {
          xmlHttpRequest.setRequestHeader(val.header, val.value);
        });
      }
      xmlHttpRequest.setRequestHeader('Accept', 'application/json');
      xmlHttpRequest.onreadystatechange = function () {
        // In local files, status is 0 upon success in Mozilla Firefox
        if (xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
          if (xmlHttpRequest.status !== 200) {
            reject(JSON.parse(xmlHttpRequest.response.length > 0 ? xmlHttpRequest.response : '{}'));
          }
          resolve(JSON.parse(xmlHttpRequest.response.length > 0 ? xmlHttpRequest.response : '{}'));
        }
      };
      xmlHttpRequest.send(file);
    } catch (error: any) {
      triggerBotActivityEvent(error);
      reject(absolute ? error : error.message);
    }
  });
};

const handleError = (I18n: any, openSnackBar: (message: any, type: IMessageType) => any) => async (
  error: any,
  customErrorMessage?: string,
  sendErrorCode?: boolean,
  manualCatchHandle = false
) => {
  const { response } = error;
  if (response?.status === 522 && response.headers?.['waf-block-header'] === 'true')
    triggerBotActivityEvent(error);
  const erroResponseCode = response?.data.statusCode;
  let errorMessage =
      response?.data?.message || error?.message || customErrorMessage || 'Internal Server Error',
    status = 0,
    messageCode = 'INTERNAL_SERVER_ERROR',
    traceId = '';

  if (erroResponseCode === 403) return await unauthorizedUserErrorHandler(response?.data, I18n);

  if (response) {
    status = response.status;
    const isUnauthorized = status === 401 || response.data.statusCode === 401;
    traceId = response.headers['x-amzn-trace-id'] || '';
    messageCode = isUnauthorized ? 'UNAUTHORIZED' : response.data.messageCode || messageCode;
    if (isUnauthorized) logOutTheUser('Session Expired', 'error');
    if (response.data?.messageCode) {
      const msg = I18n._format(
        I18n[response.data.messageCode] || response.data.message,
        Array.isArray(response.data.params) ? response.data.params : []
      );
      if (msg) errorMessage = msg;
    }
  }

  if (response?.status === 422) {
    if (response?.data.status === 'FAIL') errorMessage = response?.data?.errors[0]?.messageCode;
    else errorMessage = response?.data?.errors[0]?.messageDescription ?? error.message;
  }

  try {
    await Auth.currentAuthenticatedUser();
  } catch (error) {
    return logOutTheUser(errorMessage, 'error');
  }

  if (!manualCatchHandle) openSnackBar(errorMessage, eMessageType.error);
  if (sendErrorCode) errorMessage = response.data?.messageCode || 'INVALID_REQUEST';
  // eslint-disable-next-line no-throw-literal
  throw {
    name: 'NetworkError',
    message: errorMessage,
    errorBody: response ? response?.data : null,
    status: status,
    messageCode: messageCode,
    traceId: traceId,
  };
};

export const useGetDataService = <T,>({
  url,
  overrideQueryKey,
  queryParams,
  ...rest
}: IuseDataService): UseQueryResult<T> => {
  const {
    openSnackBar,
  }: {
    openSnackBar(message: any, type: IMessageType): any;
  } = useErrorHandlerContext();

  const I18n = useContext(I18nContext);

  return useQuery<T, T, T, any>({
    queryKey: [overrideQueryKey ? overrideQueryKey : url],
    queryFn: fetchUrl,
    refetchOnWindowFocus: false,
    meta: { I18n, openSnackBar, url, ...queryParams },
    retry: false,
    ...rest,
  });
};

export const useMutationService = ({
  url,
  queryParams,
  ...rest
}: IuseMutationService): UseMutationResult<any, any, mutationVariables | null, any> => {
  const {
    openSnackBar,
  }: {
    openSnackBar(message: any, type: IMessageType): any;
  } = useErrorHandlerContext();

  const I18n = useContext(I18nContext);

  return useMutation({
    mutationFn: async (params: any) =>
      fetchUrl({ meta: { ...queryParams, url, ...params, I18n, openSnackBar } }),
    ...rest,
  });
};

export const useFileUploadMutationService = ({
  ...rest
}: IuseFileUploadMutationService): UseMutationResult<
  any,
  any,
  IfileUploadMutationVariables | null,
  any
> => {
  return useMutation({
    mutationFn: async (params: any) => await fileUpload(params),
    ...rest,
  });
};

export const useGetQueriesDataService = <T,>(params: IuseDataService[]): UseQueryResult<T>[] => {
  const {
    openSnackBar,
  }: { openSnackBar(message: any, type: IMessageType): void } = useErrorHandlerContext();
  const I18n = useContext(I18nContext);

  const queries: UseQueryOptions[] = params.map(
    ({ url, overrideQueryKey, queryParams, ...rest }) => ({
      queryKey: [overrideQueryKey ? overrideQueryKey : url],
      queryFn: fetchUrl,
      refetchOnWindowFocus: false,
      meta: { I18n, url, openSnackBar, ...queryParams },
      retry: false,
      ...rest,
    })
  );

  return useQueries({ queries });
};

export const useQueryClientService = (queryClient: QueryClient, I18n: any, openSnackBar: any) => {
  const getQueryClientService = async <T,>({
    url,
    overrideQueryKey,
    queryParams,
    ...rest
  }: IuseDataService): Promise<T> =>
    queryClient.fetchQuery<T, T, T, any>({
      queryKey: [overrideQueryKey ? overrideQueryKey : url],
      queryFn: fetchUrl,
      refetchOnWindowFocus: false,
      meta: { I18n, openSnackBar, url, ...queryParams },
      retry: false,
      ...rest,
    });

  const getExportDataClientService = async <T,>({
    url,
    fileName,
    overrideQueryKey,
    queryParams = {},
    ...rest
  }: IExportData): Promise<T> => {
    const { additionalParams, ...restQueryParams } = queryParams;

    return queryClient.fetchQuery<T, T, T, any>({
      queryKey: [overrideQueryKey ? overrideQueryKey : url],
      queryFn: async (params: any) => {
        try {
          const file = await fetchUrl(params);
          return await downloadFile(file, fileName);
        } catch (error) {
          throw error;
        }
      },
      refetchOnWindowFocus: false,
      meta: {
        I18n,
        openSnackBar,
        url,
        additionalParams: {
          body: additionalParams?.body || {},
          headers: {
            accept: 'application/excel',
            ...additionalParams?.headers,
          },
          responseType: 'blob',
          response: true,
        },
        ...restQueryParams,
      },
      ...rest,
    });
  };

  return { getQueryClientService, getExportDataClientService };
};
