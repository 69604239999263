import { combineReducers } from 'redux';
import userReducer from './userReducer';
import configReducer from './configReducer';
import stockGroupReducer from './stockGroupReducer';
import urlReducer from './urlReducer';
import cartReducer from './cartReducer';
import sideMenuReducer from './sideMenuReducer';
import inventoryReducer from './inventoryReducer';
import checkoutReducer from './checkoutReducer';
import orderComputeUpdateReducer from './orderComputeReducer';
import settingReducer from './settingReducer';
import orderServicesReducer from './orderServicesReducer';
import salesOrderReducer from './salesOrderReducer';
import systemMessageReducer from './systemMessageReducer';
import networkSystemMessageReducer from './networkSystemMessageReducer';
import analyticsEventReducer from './analyticsEventReducer';
import flagsReducer from './flagsReducer';
import bannerReducer from './bannerReducer';
import pusherEventReducer from './pusherEventReducer';
import { getConfigFromLocalStorage } from '../../hooks/useFetchConfig';

const rootReducer = combineReducers({
  userState: userReducer,
  configState: configReducer,
  systemMessageState: systemMessageReducer,
  networkSystemMessageState: networkSystemMessageReducer,
  stockGroupState: stockGroupReducer,
  urlState: urlReducer,
  flagsState: flagsReducer,
  cartState: cartReducer,
  sideMenuState: sideMenuReducer,
  inventoryState: inventoryReducer,
  checkoutState: checkoutReducer,
  bannerState: bannerReducer,
  analyticsEventState: analyticsEventReducer,
  orderComputeState: orderComputeUpdateReducer,
  settingState: settingReducer,
  orderServicesState: orderServicesReducer,
  salesOrderSettingsState: salesOrderReducer,
  pusherEventState: pusherEventReducer,
});

export const InitialState = () => {
  let config = getConfigFromLocalStorage();
  config = config && { ...config, isLoaded: true };

  // const checkoutState = JSON.parse(localStorage.getItem('checkoutPayload') || '{}');

  const initState = {
    userState: null,
    configState: config || { isLoaded: false },
    stockGroupState: { isLoaded: false, results: null },
    systemMessageState: {},
    networkSystemMessageState: {},
    inventoryState: {},
    checkoutState: {},
    bannerState: [],
    urlState: { url: '', preventWindowClose: null, preventRouteChange: null, preventLogout: null },
    flagsState: {},
    cartState: {
      currentTab: 0,
    },
    sideMenuState: {
      Home: 'show',
      StockList: 'show',
      Auctions: 'hide',
      Orders: 'hide',
      Preorders: 'hide',
      Returns: 'hide',
      Cart: 'hide',
    },
    orderComputeState: {
      cartSubtotal: 0,
      additionalServiceFees: null,
      freight: null,
      tax: null,
      fees: null,
      cartTotalQuantity: 0,
      existingOrderSubtotal: 0,
      existingOrderTotalQuantity: 0,
      combinedSubtotal: 0,
      combinedTotalQuantity: 0,
      totalPrice: 0,
      taxDescription: '',
      metadata: {
        feesApplicable: null,
        feeType: null,
        fees: null,
      },
    },
    orderServicesState: {},
    salesOrderSettingsState: null,
  };
  return initState;
};

export default rootReducer;
