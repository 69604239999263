import React from 'react';
import SideMenu from '../components/SideMenu/SideMenu';
import PageLevelErrorBoundary from '../components/ErrorHandlers/PageLevelErrorBoundary';
import useDataService from '../hooks/useDataService';

function FrameLayout(props: any) {
  const { openSnackBar } = useDataService();
  return (
    <div className="px-v-dashboard grid-x">
      <SideMenu />
      <main className="cell auto px-v-dashboard-main">
        <div className="px-frame-view">
          <PageLevelErrorBoundary openSnackBar={openSnackBar}>
            {props.children}
          </PageLevelErrorBoundary>
        </div>
      </main>
    </div>
  );
}

export default FrameLayout;
