import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import GlobalButtonWrapper from './GlobalButtonWrapper';

interface ITertiaryButton extends ButtonProps {
  component?: any;
  to?: any;
}

function TertiaryButton({ children, ...props }: ITertiaryButton) {
  return (
    <GlobalButtonWrapper props={props}>
      <Button variant="text" color="primary">
        {children}
      </Button>
    </GlobalButtonWrapper>
  );
}

export default TertiaryButton;
