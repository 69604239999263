import React, { useContext } from 'react';
import Dialog from '../Dialog/Dialog';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import { useHistory } from 'react-router-dom';
import { I18nContext } from '../../I18n';

interface IAutoCheckoutPromptDialog {
  isDialogOpen: boolean;
  closeDialog: () => void;
  acknowledgeAutoCheckoutPromptDialog: () => void;
  warehouseGroup: number | null;
}

function AutoCheckoutPromptDialog({
  isDialogOpen,
  closeDialog,
  acknowledgeAutoCheckoutPromptDialog,
  warehouseGroup,
}: IAutoCheckoutPromptDialog) {
  const I18n = useContext(I18nContext);
  const history = useHistory();

  function onClickGoToPreferences() {
    const searchParams = new URLSearchParams();
    if (warehouseGroup) searchParams.set('warehouseGroup', warehouseGroup.toString());
    history.push({
      pathname: '/preferences',
      search: searchParams.toString(),
    });
  }

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={I18n.autoCheckoutPromptTitle || 'Auto-Checkout for Accepted Offers'}
      content={
        <div>
          <p>
            {I18n.autoCheckoutPromptInstruction ||
              `Enable Auto-Checkout to automatically place orders for you when your offers are
            accepted. This applies to eligible warehouse groups.`}
          </p>
          <p>
            {I18n.autoCheckoutPromptConfirmationText ||
              'Would you like to update your Checkout Preferences now?'}
          </p>
        </div>
      }
      actions={
        <div className="grid-x px-autocheckout-prompt-actions">
          <TertiaryButton onClick={closeDialog}>MAYBE LATER</TertiaryButton>
          <div className="grid-y margin-left-2">
            <PrimaryButton onClick={onClickGoToPreferences}>YES, GO TO PREFERENCES</PrimaryButton>
            <TertiaryButton className="margin-top-2" onClick={acknowledgeAutoCheckoutPromptDialog}>
              NO, AND DON'T REMIND ME
            </TertiaryButton>
          </div>
        </div>
      }
    />
  );
}

export default AutoCheckoutPromptDialog;
