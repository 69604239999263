import { CircularProgress } from '@material-ui/core';
import React from 'react';
import Dialog from '../Dialog/Dialog';

interface ILoadingDialog {
  isDialogOpen: boolean;
}

function LoadingDialog({ isDialogOpen }: ILoadingDialog) {
  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={() => {}}
      content={<CircularProgress />}
      actions={<></>}
    />
  );
}

export default LoadingDialog;
