import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
//import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import {preFilterMiddleware} from './middlewares/preFiltering';

declare const window: any;

export default function configureStore(initialState: any) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, preFilterMiddleware)));
}
