import React from 'react';
import { I18nContext } from '../../I18n';
import './Homepage.scss';
import { getConfigFromLocalStorage } from '../../hooks/useFetchConfig';

function Homepage(props: any) {
  const I18n = React.useContext(I18nContext);

  React.useEffect(() => {
    const config = getConfigFromLocalStorage();
    document.title = String(config.siteTitle) + ` - ${I18n.login || 'Login'}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-v-homepage grid-x">
      <main
        className={`cell small-12 grid-x align-center align-top ${
          props.pxFrameView ? ' px-frame-view' : ''
        }`}
      >
        {props.children}
      </main>
    </div>
  );
}

export default Homepage;
