import React from 'react';

import {
  Pagination as MuiPagination,
  PaginationItem,
  PaginationProps,
  PaginationRenderItemParams,
} from '@material-ui/lab';

function RenderItem(params: PaginationRenderItemParams) {
  let page = params.page as any;
  // workaround for google translation issue.
  if (params.type === 'page') page = <span>{params.page}</span>;
  return <PaginationItem {...params} page={page} />;
}

export default function Pagination({ renderItem = RenderItem, ...props }: PaginationProps) {
  return <MuiPagination renderItem={renderItem} {...props} />;
}
