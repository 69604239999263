import * as types from '../actions/actionTypes';

export default function sideMenuReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.SIDE_MENU_ITEMS_SET:
      return { ...state, ...action.sideMenuItems };
    default:
      return state;
  }
}
