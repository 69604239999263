import * as types from './actionTypes';

export function urlSet(url: any) {
  return { type: types.URL_CHANGE, url };
}

export function setPreventWindowClose(preventWindowClose: any) {
  return { type: types.URL_PREVENT_WINDOW_CLOSE, preventWindowClose };
}

export function setPreventRouteChange(preventRouteChange: any) {
  return { type: types.URL_PREVENT_ROUTE_CHANGE, preventRouteChange };
}

export function setPreventLogout(preventLogout: any) {
  return { type: types.URL_PREVENT_LOGOUT, preventLogout };
}

