import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { publicPrivateRoute } from '../_lib/constant';
import FrameLayout from '../layouts/layout-dashboard-frame';
import Homepage from '../views/Homepage/Homepage';

const PublicAndPrivateRoute = ({ children, ...rest }: any) => {
  const { requiresAccountSetup, isSignedIn, gracePeriodBuyer } = rest.redux.props.userState;
  const history = useHistory();
  history.listen((location: any) => {
    if (!location.search) window.scrollTo(0, 0);
  });

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (requiresAccountSetup || gracePeriodBuyer)
          return <Homepage pxFrameView={true}>{children}</Homepage>;

        if (isSignedIn && Object.values(publicPrivateRoute).includes(location.pathname))
          return <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>;

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PublicAndPrivateRoute;
