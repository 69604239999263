import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { I18nContext } from '../../I18n';
import { loading } from '../../PhonexRouter';

interface IFiltersPersistWrapper {
  localStorageKey: string;
  children: JSX.Element;
  path: string;
}

const FiltersPersistWrapper = ({ localStorageKey, path, children }: IFiltersPersistWrapper) => {
  const history = useHistory();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const I18n = React.useContext(I18nContext);
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    // if url has search params override localstorage
    if ((searchParams as any).size) {
      localStorage.setItem(localStorageKey, history.location.search);
    }
    const storedValue = localStorage.getItem(localStorageKey);
    if (storedValue) {
      history.push({ search: storedValue });
    }
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    const unListen = history.listen((location: any) => {
      if (location.pathname === path) localStorage.setItem(localStorageKey, location.search);
    });
    return unListen;
  }, [history, localStorageKey, path]);

  if (!isLoaded) return loading(I18n);

  return children;
};

export default FiltersPersistWrapper;
