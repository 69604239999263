import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { I18nContext } from '../../I18n';

interface IFileWellProps {
  handleDrop: any;
  acceptedFiles?: any;
  filesLimit?: number;
  required?: boolean;
  error?: boolean;
  name?: string;
  helperText?: string;
  className?: string;
  getDropRejectMessage?: () => string;
}

const FileWell = ({
  handleDrop,
  acceptedFiles,
  filesLimit,
  required,
  name,
  helperText,
  error,
  className,
  getDropRejectMessage,
}: IFileWellProps) => {
  const I18n = React.useContext(I18nContext);
  const handleChange = (files: any) => {
    handleDrop(files);
  };

  return (
    <>
      <DropzoneArea
        filesLimit={filesLimit}
        acceptedFiles={acceptedFiles}
        onChange={handleChange.bind(this)}
        maxFileSize={10485760}
        showFileNames={true}
        dropzoneClass={`${className} ${error ? 'Mui-error' : ''}`}
        dropzoneText={helperText || I18n.dragDropFileHere || 'Drag and drop a file here or click'}
        data-id="uploadBox"
        inputProps={{
          required: !!required,
          name: name || 'uploadBox',
        }}
        getFileAddedMessage={(fileName) =>
          I18n._format(I18n.fileSuccessFullyAdded || 'File ${1} successfully added.', [fileName])
        }
        getFileRemovedMessage={(fileName) =>
          I18n._format(I18n.fileRemoved || 'File ${1} removed.', [fileName])
        }
        getDropRejectMessage={getDropRejectMessage}
      />
    </>
  );
};

export default FileWell;
