import React, { forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import { I18nContext } from '../../../I18n';
import { dataFormatting, getAttrLabel } from '../../../_lib/lib';

export default forwardRef((props: any, ref: any) => {
  const I18n = React.useContext(I18nContext);

  const {
    data,
    stockListSettings,
    warehousesObj,
    curTenantSettings,
    warehouseGroupAcceptsOffers,
  } = props;
  const maxQuantityToDisplay = stockListSettings['maxItemQuantityToBeDisplayed'];

  return (
    <div id="invalid-request" className="cell small-12" ref={ref}>
      <Box className="cell small-12 px-review-box px-box" boxShadow={2}>
        <div className="grid-x margin-bottom-1">
          <div className="cell small-12">
            <WarningIcon className="margin-right-1 vertical-middle px-review-icons" color="error" />
            <Typography className="inline-block" variant="h5">
              {I18n._format(
                I18n.xReqNotImported || 'There are ${1} requests that could not be imported',
                [data.length]
              )}
            </Typography>
          </div>
        </div>
        <div className="grid-x">
          <div className="cell small-3 text-muted capitalize">{I18n.item || 'Item'}</div>
          <div className="cell small-2 text-muted grid-x align-middle align-center text-center uppercase">
            {I18n.sku || getAttrLabel(curTenantSettings, 'item_number', 'SKU')}
          </div>
          <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
            {I18n.warehouse || getAttrLabel(curTenantSettings, 'warehouse', 'Warehouse')}
          </div>
          {!warehouseGroupAcceptsOffers ? (
            <>
              <div className="cell small-1 text-muted grid-x align-middle align-center text-center capitalize">
                {I18n.availableQty || 'Available Quantity'}
              </div>
              <div className="cell small-2 text-muted grid-x align-middle align-center text-center text-center capitalize">
                {I18n.listPrice || 'List Price'}
              </div>
            </>
          ) : null}
          <div className="cell small-2 text-muted grid-x align-middle align-center text-center text-center capitalize">
            {I18n.newQuantity || 'New Quantity'}
            {/*br*/}
          </div>
          {warehouseGroupAcceptsOffers && (
            <div className="cell small-2 text-muted grid-x align-middle align-center text-center text-center capitalize">
              {I18n.newPrice || 'New Price'}
              {/*br*/}
            </div>
          )}
        </div>
        {data.map((item: any) => {
          const warehouse = stockListSettings?.['warehouseLabels']?.[item.warehouse] ?? item.warehouse;
          return (
            <div key={item.itemNumberWarehouse} className="grid-x margin-top-1">
              <div className="cell small-3 grid-x align-middle align-left">
                <label className="wrap-word-any">{item.description || '-'}</label>
              </div>
              <div className="cell small-2 grid-x align-middle align-center text-center">
                <label className="wrap-word-any">{item.itemNumber}</label>
              </div>
              <div className="cell small-1 grid-x align-middle align-center text-center">
                <label className="wrap-word-space">{warehouse}</label>
              </div>
              {!warehouseGroupAcceptsOffers ? (
                <>
                  <div className="cell small-1 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">
                      {maxQuantityToDisplay
                        ? item.availableQuantity > maxQuantityToDisplay
                          ? `${maxQuantityToDisplay}+`
                          : item.availableQuantity
                        : item.availableQuantity}
                    </label>
                  </div>
                  <div className="cell small-2 grid-x align-middle align-center text-center">
                    <label className="wrap-word-any">
                      {dataFormatting('currency-with-no-decimal', item.listPrice)}
                    </label>
                  </div>
                </>
              ) : null}
              <div className="cell small-2 grid-x align-middle align-center text-center">
                <label className="wrap-word-any">{item.offerQuantity}</label>
              </div>
              {warehouseGroupAcceptsOffers && (
                <div className="cell small-2 grid-x align-middle align-center text-center">
                  <label className="wrap-word-any">
                    {dataFormatting('currency-with-no-decimal', item.offerPrice)}
                  </label>
                </div>
              )}
              <Typography
                color="error"
                className="cell auto grid-x align-middle align-center text-center"
              >
                {item.reason}
              </Typography>
            </div>
          );
        })}
      </Box>
    </div>
  );
});
