import React from 'react';
import './DeactivatedTenant.scss';

interface IDeactivatedTenant {
  content: string;
}

const DeactivatedTenant = ({ content }: IDeactivatedTenant) => {
  return (
    <div className="grid-x grid-container">
      <div className="cell small-12 text-center">
        <h2 id="px-deactivated-tenant-content">{content}</h2>
      </div>
    </div>
  );
};

export default DeactivatedTenant;
