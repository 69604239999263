import { SvgIcon } from '@material-ui/core';
import React from 'react';
export const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
  </svg>
);

export const WavingHandIcon = (props: any) => (
  <SvgIcon {...props}>
    <path d="M23,17c0,3.31-2.69,6-6,6v-1.5c2.48,0,4.5-2.02,4.5-4.5H23z M1,7c0-3.31,2.69-6,6-6v1.5C4.52,2.5,2.5,4.52,2.5,7H1z M8.01,4.32l-4.6,4.6c-3.22,3.22-3.22,8.45,0,11.67s8.45,3.22,11.67,0l7.07-7.07c0.49-0.49,0.49-1.28,0-1.77 c-0.49-0.49-1.28-0.49-1.77,0l-4.42,4.42l-0.71-0.71l6.54-6.54c0.49-0.49,0.49-1.28,0-1.77s-1.28-0.49-1.77,0l-5.83,5.83l-0.71-0.71 l6.89-6.89c0.49-0.49,0.49-1.28,0-1.77s-1.28-0.49-1.77,0l-6.89,6.89L11.02,9.8l5.48-5.48c0.49-0.49,0.49-1.28,0-1.77 s-1.28-0.49-1.77,0l-7.62,7.62c1.22,1.57,1.11,3.84-0.33,5.28l-0.71-0.71c1.17-1.17,1.17-3.07,0-4.24l-0.35-0.35l4.07-4.07 c0.49-0.49,0.49-1.28,0-1.77C9.29,3.83,8.5,3.83,8.01,4.32z" />
  </SvgIcon>
);

export const DeployedCodeIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
    className="MuiSvgIcon-root"
  >
    <path d="M443.846-111.771 176.155-265.847q-17.077-9.846-26.615-26.308-9.539-16.461-9.539-36.153v-303.384q0-19.692 9.539-36.153 9.538-16.462 26.615-26.308l267.691-154.076q17.077-9.846 36.154-9.846t36.154 9.846l267.691 154.076q17.077 9.846 26.615 26.308 9.539 16.461 9.539 36.153v303.384q0 19.692-9.539 36.153-9.538 16.462-26.615 26.308L516.154-111.771q-17.077 9.846-36.154 9.846t-36.154-9.846Zm6.155-350.999v285.54l24.614 14.153q2.692 1.538 5.385 1.538 2.693 0 5.385-1.538l24.614-14.153v-285.54L760-607.539v-25.845q0-1.923-.962-3.847-.961-1.923-2.884-3.077l-29.153-16.922L480-514.461 232.999-657.23l-29.153 16.922q-1.923 1.154-2.884 3.077-.962 1.924-.962 3.847v25.845L450.001-462.77Z" />
  </svg>
);

export const OrderApprove = ({ className }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      className={className}
    >
      <path d="m691-150 139-138-42-42-97 95-39-39-42 43 81 81ZM240-600h480v-80H240v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40ZM120-80v-680q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v267q-28-14-58.5-20.5T720-520H240v80h284q-17 17-31.5 37T467-360H240v80h203q-2 10-2.5 19.5T440-240q0 42 11.5 80.5T486-86l-6 6-60-60-60 60-60-60-60 60-60-60-60 60Z" />
    </svg>
  );
};

export const Package2Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M440-91v-366L120-642v321q0 22 10.5 40t29.5 29L440-91Zm80 0 280-161q19-11 29.5-29t10.5-40v-321L520-457v366Zm159-550 118-69-277-159q-19-11-40-11t-40 11l-79 45 318 183ZM480-526l119-68-317-184-120 69 318 183Z" />
    </svg>
  );
};

export const FrontHandIcon = ({ color, className }: { color: string; className?: string }) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className={className}>
      <defs>
        <path
          d="M17.5,8 C17.33,8 17.16,8.02 17,8.05 L17,4.5 C17,3.12 15.88,2 14.5,2 C14.31,2 14.13,2.02 13.96,2.06 C13.75,0.89 12.73,0 11.5,0 C10.44,0 9.54,0.66 9.17,1.59 C8.96,1.53 8.73,1.5 8.5,1.5 C7.12,1.5 6,2.62 6,4 L6,4.55 C5.84,4.52 5.67,4.5 5.5,4.5 C4.12,4.5 3,5.62 3,7 L3,15.5 C3,20.19 6.81,24 11.5,24 C16.19,24 20,20.19 20,15.5 L20,10.5 C20,9.12 18.88,8 17.5,8 Z M18,15.5 C18,19.09 15.09,22 11.5,22 C7.91,22 5,19.09 5,15.5 L5,7 C5,6.72 5.22,6.5 5.5,6.5 C5.78,6.5 6,6.72 6,7 L6,12 L8,12 L8,4 C8,3.72 8.22,3.5 8.5,3.5 C8.78,3.5 9,3.72 9,4 L9,11 L11,11 L11,2.5 C11,2.22 11.22,2 11.5,2 C11.78,2 12,2.22 12,2.5 L12,11 L14,11 L14,4.5 C14,4.22 14.22,4 14.5,4 C14.78,4 15,4.22 15,4.5 L15,13.42 C13.23,14.19 12,15.95 12,18 L14,18 C14,16.34 15.34,15 17,15 L17,10.5 C17,10.22 17.22,10 17.5,10 C17.78,10 18,10.22 18,10.5 L18,15.5 Z"
          id="path-1"
        ></path>
      </defs>
      <g id="icon/front-hand" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-1"></use>
        <g id="Color/Icon/Primary" mask="url(#mask-2)" fill={color}>
          <rect id="Color" x="0" y="0" width="24" height="24"></rect>
        </g>
      </g>
    </svg>
  );
};
