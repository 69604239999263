import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import type { CSSProperties } from 'react';

//core and it's types
import * as echarts from 'echarts/core';
import type { EChartsType, ComposeOption, SetOptionOpts } from 'echarts/core';

//components and it's options
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from 'echarts/components';
import type {
  TitleComponentOption,
  TooltipComponentOption,
  GridComponentOption,
  DatasetComponentOption,
} from 'echarts/components';

//Charts and it's options
import { PieChart } from 'echarts/charts';
import type { PieSeriesOption } from 'echarts/charts';

import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

export type ECOption = ComposeOption<
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
  //Add the chart options if any
  | PieSeriesOption
>;

export interface EChartsProps {
  option: ECOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: 'light' | 'dark';
}

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  //Add all the charts we use
  PieChart,
]);

const EChart = ({ option, style, settings, loading, theme }: EChartsProps, ref: any) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      getChartDimensions: () => {
        if (!chartRef.current) return { width: 0, height: 0 };
        const { clientWidth, clientHeight } = chartRef.current;
        return { width: clientWidth, height: clientHeight };
      },
    }),
    []
  );

  useEffect(() => {
    let chart: EChartsType | undefined;
    if (chartRef.current !== null) {
      chart = echarts.init(chartRef.current, theme);
    }

    chart?.on('legendselectchanged', (params: any) => {
      const legendName = params.name.split(' ')[0];
      if (legendName.includes('Bookings') || legendName.includes('Offers')) return;
      params.selected[params.name] = !params.selected[params.name];
      chart?.setOption({
        legend: {
          selected: params.selected,
        },
        animation: false,
      });
    });

    // add chart resize listener
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart: EChartsType | undefined = echarts.getInstanceByDom(chartRef.current)!;
      chart.setOption(option, settings);
    }
  }, [option, settings, theme]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current)!;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading, theme]);

  return <div ref={chartRef} className="px-chart" />;
};

export default forwardRef(EChart);
