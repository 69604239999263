import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ReachIcon = (props: any) => (
  <SvgIcon {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M8,5 C8.9,5 9.75,5.19 10.53,5.5 C7.73,7.84 7.5,11.03 7.5,12 L7.50061784,12.088508 C7.51524082,13.1515918 7.81,16.2268571 10.53,18.5 C9.75,18.81 8.9,19 8,19 C4.14,19 1,15.86 1,12 C1,8.14 4.14,5 8,5 Z M16,5 C19.86,5 23,8.14 23,12 C23,15.86 19.86,19 16,19 C15.1,19 14.25,18.81 13.47,18.5 C14.08,17.99 14.57,17.43 14.96,16.87 C15.29,16.95 15.64,17 16,17 C18.76,17 21,14.76 21,12 C21,9.24 18.76,7 16,7 C15.64,7 15.29,7.05 14.96,7.13 C14.57,6.57 14.08,6.01 13.47,5.5 C14.25,5.19 15.1,5 16,5 Z M12,6.26 C13.6568542,6.26 15,8.82988554 15,12 C15,15.1701145 13.6568542,17.74 12,17.74 C10.3431458,17.74 9,15.1701145 9,12 C9,8.82988554 10.3431458,6.26 12,6.26 Z"
      id="path-1"
    ></path>
  </SvgIcon>
);
