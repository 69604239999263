import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { I18nContext } from '../../I18n';

import Dialog from '../Dialog/Dialog';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import { accountsetupPrivateRoute } from '../../_lib/constant';

interface IAccountSetupPopup {
  isDialogOpen: boolean;
  closeDialog: () => void;
}

const AccountSetupPopup = ({ isDialogOpen, closeDialog }: IAccountSetupPopup) => {
  const history = useHistory();
  const I18n = useContext(I18nContext);

  function goToAccountSetup() {
    history.push(accountsetupPrivateRoute.accountSetup);
  }

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={I18n.becomeACustomer || 'Become a Customer'}
      content={
        <div className="margin-top-1">
          <p>
            {I18n.thankYouForExploringOurStockList || 'Thank you for exploring our Stock List.'}
          </p>
          <p>
            {I18n.setupAccountWithUsToProceed ||
              'To proceed with purchasing or making offers on items, we kindly ask you to finish setting up your account with us.'}
          </p>
          <p>
            {I18n.essentialStepForCustomerRegistration ||
              'This step is essential for collecting the information needed to finalize your customer registration.'}
          </p>
        </div>
      }
      actions={
        <>
          <TertiaryButton onClick={closeDialog}>
            {I18n.notReadyYet || 'NOT READY YET'}
          </TertiaryButton>
          <PrimaryButton onClick={goToAccountSetup}>
            {I18n.completeAccountSetup || 'COMPLETE ACCOUNT SETUP'}
          </PrimaryButton>
        </>
      }
    />
  );
};

export default AccountSetupPopup;
