import React, { useState, ReactNode, useEffect } from 'react';
import { getConfigFromLocalStorage } from '../../hooks/useFetchConfig';
import ShadowDom from '../../components/AtomComponents/ShadowDom';
import UnSanitizedShadowDom from '../../components/AtomComponents/UnsanitizedShadowDom';

interface BotActivityWrapperProps {
  children: ReactNode;
}

function BotActivityWrapper({ children }: BotActivityWrapperProps) {
  const [botActivityScreen, setBotActivityScreen] = useState<any>(null);

  useEffect(() => {
    // Event handler function
    const handleBidActivityDetected = (response: any) => {
      openBotActivityScreen(response.detail);
    };

    // Add event listener
    document.addEventListener('onBidActivityDetected', handleBidActivityDetected);

    return () => {
      document.removeEventListener('onBidActivityDetected', handleBidActivityDetected);
    };
  }, []);

  const openBotActivityScreen = async(response: any) => {
    if(response.status !== 522 || response.headers?.["waf-block-header"] !== "true" || !response.data) return;
    const configState = getConfigFromLocalStorage();
    setBotActivityScreen({ ...response, configState });
  };

  return (
      <>
        {botActivityScreen ? (
          <>
            <header style={{ height: '50px' }}>
              <nav
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '50px',
                  textAlign: 'right',
                  zIndex: 1299,
                  padding: '0 20px',
                  borderBottom: '1px solid rgb(211, 211, 211)',
                }}>
                <div style={{ float: 'left' }}>
                  <img
                    style={{
                      maxWidth: '100px',
                      maxHeight: '50px',
                      verticalAlign: 'middle',
                      display: 'inline-block',
                    }}
                    src={botActivityScreen?.configState?.userPoolLogo}
                    alt="Logo"
                    id="pxTopMenuLogoImage"
                  />
                </div>
              </nav>
            </header>
            <UnSanitizedShadowDom htmlString={botActivityScreen?.data} />
          </>
        ) : (
          children
        )}
      </>
  );
}

export default BotActivityWrapper;
