import { IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';
import GlobalButtonWrapper from './GlobalButtonWrapper';

interface IPrimaryIconButton extends IconButtonProps {
  children?: any;
}

const PrimaryIconButton = ({ children, ...props }: IPrimaryIconButton) => {
  return (
    <GlobalButtonWrapper props={props}>
      <IconButton color="primary" disableFocusRipple disableRipple disableTouchRipple>
        {children}
      </IconButton>
    </GlobalButtonWrapper>
  );
};
export default PrimaryIconButton;
